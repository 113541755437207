//src/components/flows/InputCollectionProperties.js
import React, { useState } from 'react';
import {
    Type,
    Calendar,
    Hash,
    Phone,
    AtSign,
} from 'lucide-react';

const InputCollectionProperties = ({ data, onChange }) => {
    const [activeTab, setActiveTab] = useState('basic');

    const updateData = (updates) => {
        onChange({
            ...data,
            ...updates
        });
    };

    const inputTypes = [
        { value: 'text', icon: Type, label: 'Text' },
        { value: 'number', icon: Hash, label: 'Number' },
        { value: 'phone', icon: Phone, label: 'Phone' },
        { value: 'email', icon: AtSign, label: 'Email' },
        { value: 'date', icon: Calendar, label: 'Date' }
    ];

    return (
        <div className="space-y-6">
            {/* Input Type Selection */}
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Input Type</label>
                <div className="grid grid-cols-3 gap-2">
                    {inputTypes.map(({ value, icon: Icon, label }) => (
                        <button
                            key={value}
                            onClick={() => updateData({ inputType: value })}
                            className={`
                p-3 flex flex-col items-center gap-1 rounded-lg border-2 transition-all
                ${data.inputType === value
                                    ? 'border-violet-500 bg-violet-50 text-violet-700'
                                    : 'border-gray-200 hover:border-gray-300 text-gray-600 hover:bg-gray-50'
                                }
              `}
                        >
                            <Icon size={20} />
                            <span className="text-xs font-medium">{label}</span>
                        </button>
                    ))}
                </div>
            </div>

            {/* Tab Navigation */}
            {data.inputType && (
                <div className="space-y-4">
                    <div className="border-b">
                        <div className="flex space-x-2">
                            {['basic', 'validation'].map((tab) => (
                                <button
                                    key={tab}
                                    onClick={() => setActiveTab(tab)}
                                    className={`
                    px-4 py-2 text-sm font-medium border-b-2 transition-colors
                    ${activeTab === tab
                                            ? 'border-violet-500 text-violet-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                        }
                  `}
                                >
                                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Basic Tab */}
                    {activeTab === 'basic' && (
                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">Question/Prompt</label>
                                <textarea
                                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
                                    rows={3}
                                    value={data.question || ''}
                                    onChange={(e) => updateData({ question: e.target.value })}
                                    placeholder="Enter your question or prompt..."
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">Variable Name</label>
                                <input
                                    type="text"
                                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
                                    value={data.variableName || ''}
                                    onChange={(e) => updateData({ variableName: e.target.value })}
                                    placeholder="Name for storing response (e.g., userPhone, customerEmail)"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">Default Value</label>
                                <input
                                    type="text"
                                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
                                    value={data.defaultValue || ''}
                                    onChange={(e) => updateData({ defaultValue: e.target.value })}
                                    placeholder="Default value if no input provided"
                                />
                            </div>
                        </div>
                    )}

                    {/* Validation Tab */}
                    {activeTab === 'validation' && (
                        <div className="space-y-4">
                            <div className="flex items-center gap-2">
                                <input
                                    type="checkbox"
                                    id="required"
                                    className="rounded border-gray-300 text-violet-600 focus:ring-violet-500"
                                    checked={data.required || false}
                                    onChange={(e) => updateData({ required: e.target.checked })}
                                />
                                <label htmlFor="required" className="text-sm font-medium text-gray-700">
                                    Required Field
                                </label>
                            </div>

                            {/* Text, Email, Phone Validation */}
                            {['text', 'email', 'phone'].includes(data.inputType) && (
                                <>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">Min Length</label>
                                            <input
                                                type="number"
                                                className="w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
                                                value={data.minLength || ''}
                                                onChange={(e) => updateData({ minLength: e.target.value })}
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">Max Length</label>
                                            <input
                                                type="number"
                                                className="w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
                                                value={data.maxLength || ''}
                                                onChange={(e) => updateData({ maxLength: e.target.value })}
                                            />
                                        </div>
                                    </div>

                                    {/* Specific validation patterns for different types */}
                                    {data.inputType === 'phone' && (
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">Phone Format</label>
                                            <select
                                                className="w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
                                                value={data.phoneFormat || ''}
                                                onChange={(e) => updateData({ phoneFormat: e.target.value })}
                                            >
                                                <option value="">Select format...</option>
                                                <option value="international">International (+XX XXX XXXXXX)</option>
                                                <option value="national">National (XXX-XXX-XXXX)</option>
                                                <option value="any">Any Format</option>
                                            </select>
                                        </div>
                                    )}
                                </>
                            )}

                            {/* Number Validation */}
                            {data.inputType === 'number' && (
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">Min Value</label>
                                        <input
                                            type="number"
                                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
                                            value={data.minValue || ''}
                                            onChange={(e) => updateData({ minValue: e.target.value })}
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">Max Value</label>
                                        <input
                                            type="number"
                                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
                                            value={data.maxValue || ''}
                                            onChange={(e) => updateData({ maxValue: e.target.value })}
                                        />
                                    </div>
                                </div>
                            )}

                            {/* Date Validation */}
                            {data.inputType === 'date' && (
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">Min Date</label>
                                        <input
                                            type="date"
                                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
                                            value={data.minDate || ''}
                                            onChange={(e) => updateData({ minDate: e.target.value })}
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">Max Date</label>
                                        <input
                                            type="date"
                                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
                                            value={data.maxDate || ''}
                                            onChange={(e) => updateData({ maxDate: e.target.value })}
                                        />
                                    </div>
                                </div>
                            )}

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">Error Message</label>
                                <input
                                    type="text"
                                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
                                    value={data.errorMessage || ''}
                                    onChange={(e) => updateData({ errorMessage: e.target.value })}
                                    placeholder="Custom error message for validation failures"
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default InputCollectionProperties;