//src/components/flows/ActionNodeResponseMapping.js
import React, { useState } from 'react';
import { Link, Play, Webhook, Database, Code, Box, RefreshCw, Plus, Trash2 } from 'lucide-react';

const ActionNodeResponseMapping = ({ data, onChange }) => {
    const [newMapping, setNewMapping] = useState({ target: '', source: '' });

    const handleResponseUpdate = (updates) => {
        onChange({
            ...data,
            ...updates
        });
    };

    // Add new mapping
    const addMapping = () => {
        if (!newMapping.target || !newMapping.source) return;

        const updatedMappings = {
            ...(data.responseMapping || {}),
            [newMapping.target]: newMapping.source
        };

        handleResponseUpdate({
            responseMapping: updatedMappings
        });
        setNewMapping({ target: '', source: '' });
    };

    // Remove mapping
    const removeMapping = (key) => {
        const updatedMappings = { ...data.responseMapping };
        delete updatedMappings[key];
        handleResponseUpdate({ responseMapping: updatedMappings });
    };

    return (
        <div className="space-y-4">
            {/* Response Variable Name - Compact Layout */}
            <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
                <h4 className="text-sm font-medium text-gray-700 mb-3">Response Variable Name</h4>
                <div className="space-y-3">
                    <div>
                        <label className="block text-xs text-gray-500 mb-1">Variable Context</label>
                        <select
                            className="w-full rounded-md border-gray-300 text-sm"
                            value={data.variableContext || 'flow'}
                            onChange={(e) => handleResponseUpdate({ variableContext: e.target.value })}
                        >
                            <option value="">Select Context</option>
                            <option value="flow">Flow Context</option>
                            <option value="user">User Context</option>
                            <option value="session">Session Context</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-xs text-gray-500 mb-1">Variable Name</label>
                        <input
                            type="text"
                            className="w-full rounded-md border-gray-300 text-sm"
                            value={data.variableName || ''}
                            onChange={(e) => handleResponseUpdate({ variableName: e.target.value })}
                            placeholder="apiResponse"
                        />
                    </div>
                </div>
            </div>

            {/* Response Mapping Section */}
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    Response Mapping
                </label>

                {/* Existing Mappings */}
                <div className="space-y-2 mb-4">
                    {Object.entries(data.responseMapping || {}).map(([target, source]) => (
                        <div key={target} className="flex items-center gap-2 group">
                            <div className="flex-1 bg-gray-50 rounded-lg p-2 border border-gray-200">
                                <div className="text-sm text-gray-900">
                                    <span className="font-medium">{target}</span> = {source}
                                </div>
                            </div>
                            <button
                                onClick={() => removeMapping(target)}
                                className="p-1.5 text-gray-400 hover:text-red-600 opacity-0 group-hover:opacity-100 transition-opacity"
                                title="Remove mapping"
                            >
                                <Trash2 size={16} />
                            </button>
                        </div>
                    ))}
                </div>

                {/* Add New Mapping Form */}
                <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
                    <h4 className="text-sm font-medium text-gray-700 mb-3">Add New Mapping</h4>
                    <div className="space-y-3">
                        <div>
                            <label className="block text-xs text-gray-500 mb-1">
                                Target Variable
                            </label>
                            <input
                                type="text"
                                className="w-full rounded-md border-gray-300 text-sm"
                                value={newMapping.target}
                                onChange={(e) => setNewMapping(prev => ({ ...prev, target: e.target.value }))}
                                placeholder="user.name"
                            />
                        </div>
                        <div>
                            <label className="block text-xs text-gray-500 mb-1">
                                Source Path
                            </label>
                            <input
                                type="text"
                                className="w-full rounded-md border-gray-300 text-sm"
                                value={newMapping.source}
                                onChange={(e) => setNewMapping(prev => ({ ...prev, source: e.target.value }))}
                                placeholder="response.data.name"
                            />
                        </div>
                        <button
                            onClick={addMapping}
                            disabled={!newMapping.target || !newMapping.source}
                            className="w-full py-2 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                        >
                            Add Mapping
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActionNodeResponseMapping;