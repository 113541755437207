// src/components/templates/TemplateForm.js
import React, { useState,useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiService } from '../../services/api';
import {
    X,
    Plus,
    Image,
    Video,
    FileText,
    MapPin,
    Type,
    Phone,
    Link,
    MessageSquare,
    Info
} from 'lucide-react';

const ComponentTypeIcons = {
    TEXT: Type,
    IMAGE: Image,
    VIDEO: Video,
    DOCUMENT: FileText,
    LOCATION: MapPin
};

const ButtonTypeIcons = {
    URL: Link,
    PHONE_NUMBER: Phone,
    QUICK_REPLY: MessageSquare
};

const TemplateForm = ({ template, onSubmit, onCancel }) => {

    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(template || {
        name: '',
        category: 'MARKETING',
        language: 'en',
        components: [
            {
                type: 'BODY',
                text: '',
                example: {
                    body_text: [[]]
                }
            }
        ]
    });


    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (id) {
            loadTemplate(id);
        }
    }, [id]);

    const loadTemplate = async (id) => {
        try {
            setLoading(true);
            const response = await apiService.getTemplateById(id);
            if (response.data?.data) {
                setFormData(response.data.data);
            }
        } catch (error) {
            console.error('Error loading template:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        
        const formatComponents = (components) => {
            // Sort components in the correct order: HEADER -> BODY -> FOOTER -> BUTTONS
            const componentOrder = { HEADER: 1, BODY: 2, FOOTER: 3, BUTTONS: 4 };
            
            // First sort the components
            const sortedComponents = [...components].sort((a, b) => 
                componentOrder[a.type] - componentOrder[b.type]
            );

            return sortedComponents.map(component => {
                switch (component.type) {
                    case 'HEADER':
                        return {
                            type: 'HEADER',
                            format: component.format || 'TEXT',
                            text: component.text,
                            /*example: {
                                header_text: [component.text] 
                            }*/
                        };

                    case 'BODY':
                        return {
                            type: 'BODY',
                            text: component.text,
                            example: {
                                body_text: [
                                    component.example?.body_text?.[0] || []
                                ]
                            }
                        };

                    case 'FOOTER':
                        return {
                            type: 'FOOTER',
                            text: component.text
                        };

                    case 'BUTTONS':
                        return {
                            type: 'BUTTONS',
                            buttons: component.buttons.map(button => {
                                const basicButton = {
                                    type: button.type,
                                    text: button.text
                                };

                                if (button.type === 'URL') {
                                    return { ...basicButton, url: button.url };
                                }
                                if (button.type === 'PHONE_NUMBER') {
                                    return { ...basicButton, phone_number: button.phoneNumber };
                                }
                                return basicButton; // QUICK_REPLY type
                            })
                        };

                    default:
                        return component;
                }
            });
        };

        const createApiData = (formData) => ({
            name: formData.name.toLowerCase().replace(/\s+/g, '_'),
            language:formData.language,
            category: formData.category,
            components: formatComponents(formData.components)
        });

       

        if (id) {
            // For updates, only send category and components
            const updateData = {
                category: formData.category,
                components: formatComponents(formData.components)
            };
            //return;
            await apiService.updateTemplate(id, updateData);
        } else {
            // For creation, send the complete template data
            const createData = createApiData(formData);
            console.log(JSON.stringify(createData));
            //return;
            await apiService.createTemplate(createData);
        }
    };

    const handleCancel = () => {
        navigate('/templates');
    };


    const validateForm = () => {
        const newErrors = {};

        // Validate template name
        if (!/^[a-z0-9_]+$/.test(formData.name)) {
            newErrors.name = 'Template name must contain only lowercase letters, numbers, and underscores';
        }

        // Validate component lengths
        formData.components.forEach((component, index) => {
            if (component.type === 'HEADER' && component.text?.length > 60) {
                newErrors[`component_${index}`] = 'Header text must not exceed 60 characters';
            }
            if (component.type === 'BODY' && component.text?.length > 1024) {
                newErrors[`component_${index}`] = 'Body text must not exceed 1024 characters';
            }
            if (component.type === 'FOOTER' && component.text?.length > 60) {
                newErrors[`component_${index}`] = 'Footer text must not exceed 60 characters';
            }
        });

        // Validate buttons
        const buttons = formData.components.find(c => c.type === 'BUTTONS')?.buttons || [];
        if (buttons.length > 3) {
            newErrors.buttons = 'Maximum 3 buttons allowed per template';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };



    const addComponent = (type) => {
        let newComponent = {
            type,
            text: '',
        };

        switch (type) {
            case 'HEADER':
                newComponent.format = 'TEXT';
                newComponent.example = {
                    header_handle: [{ type: 'text', text: '' }]
                };
                break;
            case 'BODY':
                newComponent.example = { body_text: [[]] };
                break;
            case 'BUTTONS':
                newComponent.buttons = [];
                break;
            default:
                break;

        }

        setFormData({
            ...formData,
            components: [...formData.components, newComponent]
        });
    };

    const removeComponent = (index) => {
        const newComponents = formData.components.filter((_, i) => i !== index);
        setFormData({
            ...formData,
            components: newComponents
        });
    };

    const updateComponent = (index, updates) => {
        const newComponents = [...formData.components];
        newComponents[index] = { ...newComponents[index], ...updates };
        setFormData({
            ...formData,
            components: newComponents
        });
    };

    const addButton = (componentIndex) => {
        const newComponents = [...formData.components];
        const component = newComponents[componentIndex];

        if (!component.buttons) {
            component.buttons = [];
        }

        if (component.buttons.length < 3) {
            component.buttons.push({
                type: 'QUICK_REPLY',
                text: ''
            });
            setFormData({ ...formData, components: newComponents });
        }
    };

    const updateButton = (componentIndex, buttonIndex, updates) => {
        const newComponents = [...formData.components];
        const component = newComponents[componentIndex];
        component.buttons[buttonIndex] = { ...component.buttons[buttonIndex], ...updates };
        setFormData({ ...formData, components: newComponents });
    };

    const removeButton = (componentIndex, buttonIndex) => {
        const newComponents = [...formData.components];
        const component = newComponents[componentIndex];
        component.buttons = component.buttons.filter((_, i) => i !== buttonIndex);
        setFormData({ ...formData, components: newComponents });
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
            <form onSubmit={handleSubmit} className="space-y-8">
                {/* Basic Information */}
                <div className="space-y-6">
                    <h2 className="text-xl font-semibold text-gray-900">Template Information</h2>

                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        {/* Template Name */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Template Name
                            </label>
                            <input
                                type="text"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                className={`mt-1 block w-full rounded-md shadow-sm 
                                    ${errors.name ? 'border-red-300 focus:border-red-500 focus:ring-red-500' :
                                        'border-gray-300 focus:border-blue-500 focus:ring-blue-500'}`}
                                placeholder="my_template_name"
                            />
                            {errors.name && (
                                <p className="mt-1 text-sm text-red-600">{errors.name}</p>
                            )}
                            <p className="mt-1 text-xs text-gray-500">
                                Use lowercase letters, numbers, and underscores only
                            </p>
                        </div>

                        {/* Category Selection */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Category
                            </label>
                            <select
                                value={formData.category}
                                onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            >
                                <option value="MARKETING">Marketing</option>
                                <option value="UTILITY">Utility</option>
                                <option value="AUTHENTICATION">Authentication</option>
                            </select>
                        </div>

                        {/* Language Selection */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Language
                            </label>
                            <select
                                value={formData.language}
                                onChange={(e) => setFormData({ ...formData, language: e.target.value })}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            >
                                <option value="en">English</option>
                                <option value="hi">Hindi</option>
                                <option value="ml">Malayalam</option>
                                <option value="ta">Tamil</option>
                                <option value="te">Telugu</option>
                                <option value="bn">Bengali</option>
                                <option value="gu">Gujarati</option>
                                <option value="kn">Kannada</option>
                                <option value="mr">Marathi</option>
                                <option value="pa">Punjabi</option>
                                <option value="ur">Urdu</option>
                                <option value="as">Assamese</option>
                                <option value="or">Odia</option>
                                <option value="ne">Nepali</option>
                                <option value="sd">Sindhi</option>
                                <option value="ks">Kashmiri</option>
                                <option value="doi">Dogri</option>
                                <option value="ar">Arabic</option>
                                <option value="de">German</option>
                                <option value="fr">French</option>
                                <option value="es">Spanish</option>
                                <option value="pt">Portuguese</option>
                                <option value="it">Italian</option>
                                <option value="nl">Dutch</option>
                                <option value="sv">Swedish</option>
                                <option value="no">Norwegian</option>
                                <option value="da">Danish</option>
                                <option value="fi">Finnish</option>
                                <option value="el">Greek</option>
                                <option value="tr">Turkish</option>
                                <option value="ru">Russian</option>
                                <option value="he">Hebrew</option>
                                <option value="ar">Arabic</option>
                                <option value="th">Thai</option>
                                <option value="id">Indonesian</option>
                                <option value="ja">Japanese</option>
                                <option value="ko">Korean</option>
                                <option value="zh">Chinese</option>
                                <option value="my">Burmese</option>
                                <option value="vi">Vietnamese</option>
                                {/* Add more languages as needed */}
                            </select>
                        </div>
                    </div>
                </div>

                {/* Components Section */}
                <div className="space-y-6">
                    <div className="flex justify-between items-center">
                        <h2 className="text-xl font-semibold text-gray-900">Template Components</h2>
                        <div className="flex gap-2">
                            <button
                                type="button"
                                onClick={() => addComponent('HEADER')}
                                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Add Header
                            </button>
                            <button
                                type="button"
                                onClick={() => addComponent('BODY')}
                                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Add Body
                            </button>
                            <button
                                type="button"
                                onClick={() => addComponent('FOOTER')}
                                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Add Footer
                            </button>
                            <button
                                type="button"
                                onClick={() => addComponent('BUTTONS')}
                                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Add Buttons
                            </button>
                        </div>
                    </div>
                    {/* Component List */}
                    <div className="space-y-4">
                        {formData.components.map((component, index) => (
                            <div key={index} className="bg-gray-50 border border-gray-200 rounded-lg p-4">
                                {/* Component Header */}
                                <div className="flex justify-between items-center mb-4">
                                    <div className="flex items-center gap-2">
                                        <span className="text-sm font-semibold text-gray-700 bg-gray-200 px-2 py-1 rounded">
                                            {component.type}
                                        </span>
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => removeComponent(index)}
                                        className="text-gray-400 hover:text-red-500 transition-colors"
                                    >
                                        <X size={18} />
                                    </button>
                                </div>

                                {/* Header Component */}
                                {component.type === 'HEADER' && (
                                    <div className="space-y-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Format
                                            </label>
                                            <select
                                                value={component.format || 'TEXT'}
                                                onChange={(e) => updateComponent(index, { format: e.target.value })}
                                                className="w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                            >
                                                <option value="TEXT">Text</option>
                                                <option value="IMAGE">Image</option>
                                                <option value="VIDEO">Video</option>
                                                <option value="DOCUMENT">Document</option>
                                                <option value="LOCATION">Location</option>
                                            </select>
                                        </div>

                                        {component.format === 'TEXT' && (
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                                    Text
                                                </label>
                                                <input
                                                    type="text"
                                                    value={component.text || ''}
                                                    onChange={(e) => updateComponent(index, { text: e.target.value })}
                                                    maxLength={60}
                                                    className="w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                />
                                                <p className="mt-1 text-xs text-gray-500">
                                                    Maximum 60 characters
                                                </p>
                                            </div>
                                        )}

                                        {['IMAGE', 'VIDEO', 'DOCUMENT'].includes(component.format) && (
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                                    Example URL
                                                </label>
                                                <input
                                                    type="url"
                                                    value={component.example?.header_handle?.[0]?.url || ''}
                                                    onChange={(e) => updateComponent(index, {
                                                        example: {
                                                            header_handle: [{
                                                                type: component.format.toLowerCase(),
                                                                url: e.target.value
                                                            }]
                                                        }
                                                    })}
                                                    className="w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                    placeholder="https://example.com/media"
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}

                                {/* Body Component */}
                                {component.type === 'BODY' && (
                                    <div className="space-y-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Message Text
                                            </label>
                                            <textarea
                                                value={component.text || ''}
                                                onChange={(e) => updateComponent(index, { text: e.target.value })}
                                                rows={4}
                                                maxLength={1024}
                                                className="w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                placeholder="Enter your message here. Use {{1}}, {{2}}, etc. for variables"
                                            />
                                            <p className="mt-1 text-xs text-gray-500">
                                            Maximum 1024 characters. Use &#123;&#123; 1&#125;&#125;, &#123;&#123; 2&#125;&#125;, etc. for variables
                                            </p>
                                        </div>

                                        {/* Variable Examples */}
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Variable Examples
                                            </label>
                                            <div className="space-y-2">
                                                {(component.example?.body_text?.[0] || []).map((example, varIndex) => (
                                                    <div key={varIndex} className="flex items-center gap-2">
                                                        <input
                                                            type="text"
                                                            value={example || ''}
                                                            onChange={(e) => {
                                                                const newExamples = [...(component.example.body_text[0] || [])];
                                                                newExamples[varIndex] = e.target.value;
                                                                updateComponent(index, {
                                                                    example: {
                                                                        body_text: [newExamples]
                                                                    }
                                                                });
                                                            }}
                                                            className="flex-1 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                            placeholder={`Example for {{${varIndex + 1}}}`}
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                const newExamples = [...(component.example.body_text[0] || [])];
                                                                newExamples.splice(varIndex, 1);
                                                                updateComponent(index, {
                                                                    example: {
                                                                        body_text: [newExamples]
                                                                    }
                                                                });
                                                            }}
                                                            className="p-2 text-red-600 hover:text-red-800 focus:outline-none"
                                                        >
                                                            <X size={16} />
                                                        </button>
                                                    </div>
                                                ))}
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        const newExamples = [...(component.example?.body_text?.[0] || []), ''];
                                                        updateComponent(index, {
                                                            example: {
                                                                body_text: [newExamples]
                                                            }
                                                        });
                                                    }}
                                                    className="text-sm text-blue-600 hover:text-blue-700"
                                                >
                                                    + Add Variable Example
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Footer Component */}
                                {component.type === 'FOOTER' && (
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Footer Text
                                        </label>
                                        <input
                                            type="text"
                                            value={component.text || ''}
                                            onChange={(e) => updateComponent(index, { text: e.target.value })}
                                            maxLength={60}
                                            className="w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                        />
                                        <p className="mt-1 text-xs text-gray-500">
                                            Maximum 60 characters
                                        </p>
                                    </div>
                                )}

                                {/* Buttons Component */}
                                {component.type === 'BUTTONS' && (
                                    <div className="space-y-4">
                                        {component.buttons?.map((button, buttonIndex) => (
                                            <div key={buttonIndex} className="bg-white p-3 rounded border border-gray-200">
                                                <div className="flex justify-between items-center mb-3">
                                                    <span className="text-sm font-medium text-gray-700">
                                                        Button {buttonIndex + 1}
                                                    </span>
                                                    <button
                                                        type="button"
                                                        onClick={() => removeButton(index, buttonIndex)}
                                                        className="text-gray-400 hover:text-red-500"
                                                    >
                                                        <X size={16} />
                                                    </button>
                                                </div>

                                                <div className="space-y-3">
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                                            Type
                                                        </label>
                                                        <select
                                                            value={button.type}
                                                            onChange={(e) => updateButton(index, buttonIndex, { type: e.target.value })}
                                                            className="w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                        >
                                                            <option value="QUICK_REPLY">Quick Reply</option>
                                                            <option value="URL">URL</option>
                                                            <option value="PHONE_NUMBER">Phone Number</option>
                                                        </select>
                                                    </div>

                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                                            Button Text
                                                        </label>
                                                        <input
                                                            type="text"
                                                            value={button.text || ''}
                                                            onChange={(e) => updateButton(index, buttonIndex, { text: e.target.value })}
                                                            className="w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                        />
                                                    </div>

                                                    {button.type === 'URL' && (
                                                        <div>
                                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                URL
                                                            </label>
                                                            <input
                                                                type="url"
                                                                value={button.url || ''}
                                                                onChange={(e) => updateButton(index, buttonIndex, { url: e.target.value })}
                                                                className="w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                                placeholder="https://example.com"
                                                            />
                                                        </div>
                                                    )}

                                                    {button.type === 'PHONE_NUMBER' && (
                                                        <div>
                                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                Phone Number
                                                            </label>
                                                            <input
                                                                type="tel"
                                                                value={button.phoneNumber || ''}
                                                                onChange={(e) => updateButton(index, buttonIndex, { phoneNumber: e.target.value })}
                                                                className="w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                                placeholder="+1234567890"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}

                                        {(!component.buttons || component.buttons.length < 3) && (
                                            <button
                                                type="button"
                                                onClick={() => addButton(index)}
                                                className="w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                            >
                                                Add Button
                                            </button>
                                        )}
                                    </div>
                                )}

                                {errors[`component_${index}`] && (
                                    <p className="mt-2 text-sm text-red-600">
                                        {errors[`component_${index}`]}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                    {/* Form Actions */}
                    <div className="flex justify-end space-x-3 pt-6 border-t">
                        <button
                            type="button"
                            onClick={onCancel}
                            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            Save Template
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );


};

export default TemplateForm;