//src/components/flows/FlowSaveDialog.js
import React, { useState, useEffect } from 'react';
import { Save } from 'lucide-react';
import {
    Tooltip, TooltipTrigger, TooltipContent, TooltipProvider
} from '../ui/tooltip';

const FlowSaveDialog = ({ flow, onSave }) => {
    //console.log('FlowSaveDialog.js', 'flow:', flow);
    const [isOpen, setIsOpen] = useState(false);
    const [flowName, setFlowName] = useState(flow?.name || 'New Flow');
    const [flowDescription, setFlowDescription] = useState(flow?.description || '');

    const handleSave = () => {
        onSave({
            name: flowName,
            description: flowDescription
        });
        setIsOpen(false);
    };

    // when flow changes, update the dialog state
    useEffect(() => {
        setFlowName(flow?.name || 'New Flow');
        setFlowDescription(flow?.description || '');
    }, [flow]);

    return (
        <div className="relative">

            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <button
                            onClick={() => setIsOpen(true)}
                            data-testid="save-flow-button"
                            className="flex items-center gap-2 px-4 py-2 bg-gray-100 text-gray-600 rounded hover:bg-gray-200 transition-colors w-full"
                        >
                            <Save size={16} />
                        </button>
                    </TooltipTrigger>
                    <TooltipContent side="right">
                        <p>Save Flow</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>

            {isOpen && (
                <div className="absolute left-0 bottom-full mb-2 w-72 bg-white rounded-lg shadow-lg border p-4">
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Flow Name
                            </label>
                            <input
                                type="text"
                                value={flowName}
                                onChange={(e) => setFlowName(e.target.value)}
                                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                placeholder="Enter flow name..."
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Description (Optional)
                            </label>
                            <textarea
                                value={flowDescription}
                                onChange={(e) => setFlowDescription(e.target.value)}
                                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                rows={3}
                                placeholder="Enter flow description..."
                            />
                        </div>
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={() => setIsOpen(false)}
                                className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleSave}
                                className="px-3 py-1.5 text-sm bg-blue-500 text-white rounded hover:bg-blue-600"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FlowSaveDialog;