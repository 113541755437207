///src/components/flows/FlowList.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { apiService } from '../../services/api';
import { formatDistanceToNow } from 'date-fns';
import {
    Plus,
    RefreshCcw,
    Search,
    Filter,
    Edit,
    Trash2,
    Play,
    CheckCircle,
    XCircle,
    Clock
} from 'lucide-react';
import FloatingChatWindow from './FloatingChatWindow';
const FlowList = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [flows, setFlows] = useState([]);
    const [pagination, setPagination] = useState({
        total: 0,
        limit: 10,
        offset: 0
    });
    const [filters, setFilters] = useState({
        status: '',
        search: ''
    });

    useEffect(() => {
        if (!user) {
            navigate('/login');
            return;
        }
        refreshFlows();
    }, [user, filters]);
    const [isTestModalOpen, setIsTestModalOpen] = useState(false);
    const [selectedFlowId, setSelectedFlowId] = useState(null);

    const refreshFlows = async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await apiService.getFlows({
                ...filters,
                limit: pagination.limit,
                offset: pagination.offset
            });

            if (response.data?.status === 'success' && response.data?.data) {
                setFlows(response.data.data);
                setPagination(prev => ({
                    ...prev,
                    total: response.data.total || prev.total
                }));
            } else {
                throw new Error('Invalid response structure');
            }
        } catch (err) {
            setError('Failed to load flows');
            console.error('Flow loading error:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteFlow = async (flowId) => {
        if (!window.confirm('Are you sure you want to delete this flow?')) {
            return;
        }

        try {
            await apiService.deleteFlow(flowId);
            refreshFlows();
        } catch (err) {
            setError('Failed to delete flow');
            console.error('Flow deletion error:', err);
        }
    };

    const handleEditFlow = (flowId) => {
        navigate(`/flows?flowId=${flowId}`);
    };

    const handleCreateFlow = () => {
        navigate('/flows');
    };

    //const handleTestFlow = (flowId) => {
    //   navigate(`/embedded-chat?flowId=${flowId}&mode=test`);
    //};

    const handleTestFlow = (flowId) => {
        setSelectedFlowId(flowId);
        setIsTestModalOpen(true);
    };

    const getStatusBadgeClass = (status) => {
        switch (status?.toLowerCase()) {
            case 'active':
                return 'bg-green-100 text-green-800 border-green-200';
            case 'draft':
                return 'bg-yellow-100 text-yellow-800 border-yellow-200';
            case 'inactive':
                return 'bg-red-100 text-red-800 border-red-200';
            default:
                return 'bg-gray-100 text-gray-800 border-gray-200';
        }
    };

    const getStatusIcon = (status) => {
        switch (status?.toLowerCase()) {
            case 'active':
                return <CheckCircle className="w-4 h-4 text-green-600" />;
            case 'draft':
                return <Clock className="w-4 h-4 text-yellow-600" />;
            case 'inactive':
                return <XCircle className="w-4 h-4 text-red-600" />;
            default:
                return null;
        }
    };

    return (
        <div className="h-screen flex flex-col bg-gray-50">
            {/* Header */}
            <header className="bg-white shadow sticky top-0 z-10">
                <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col space-y-4 sm:flex-row sm:justify-between sm:items-center sm:space-y-0">
                        <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">
                            Flow Manager
                        </h1>

                        <div className="flex flex-col space-y-3 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-4">
                            <button
                                onClick={refreshFlows}
                                disabled={loading}
                                className="flex items-center justify-center gap-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed"
                            >
                                <RefreshCcw size={16} className={loading ? 'animate-spin' : ''} />
                                Refresh
                            </button>
                            <button
                                onClick={handleCreateFlow}
                                className="flex items-center justify-center gap-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                            >
                                <Plus size={16} />
                                New Flow
                            </button>
                        </div>
                    </div>
                </div>
            </header>

            {/* Filters */}
            <div className="bg-white border-b">
                <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
                    <div className="flex flex-wrap gap-4">
                        <div className="flex-1 min-w-[200px]">
                            <div className="relative">
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                                <input
                                    type="text"
                                    placeholder="Search flows..."
                                    className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    value={filters.search}
                                    onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                />
                            </div>
                        </div>
                        <select
                            className="border rounded-lg px-4 py-2 bg-white"
                            value={filters.status}
                            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
                        >
                            <option value="">All Statuses</option>
                            <option value="active">Active</option>
                            <option value="draft">Draft</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <main className="flex-1 overflow-auto py-6 px-4 sm:px-6">
                <div className="max-w-7xl mx-auto">
                    {loading ? (
                        <div className="flex justify-center items-center h-64">
                            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                        </div>
                    ) : error ? (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                            {error}
                        </div>
                    ) : (
                        <div className="bg-white rounded-lg shadow">
                            <div className="overflow-x-auto">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Flow Name
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Created By
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Description
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Last Modified
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Status
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {flows.map((flow) => (
                                            <tr key={flow._id} className="hover:bg-gray-50">
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-medium text-gray-900">
                                                        {flow.name}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">
                                                    <div className="text-sm text-gray-500">
                                                        {flow.createdBy?.username || 'Unknown'}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">
                                                    <div className="text-sm text-gray-500">
                                                        {flow.description || 'No description'}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-500">
                                                        {formatDistanceToNow(new Date(flow.metadata?.lastModified), { addSuffix: true })}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeClass(flow.status)}`}>
                                                        {getStatusIcon(flow.status)}
                                                        <span className="ml-1">{flow.status}</span>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center space-x-3">
                                                        <button
                                                            onClick={() => handleEditFlow(flow._id)}
                                                            className="text-blue-600 hover:text-blue-900"
                                                            title="Edit Flow"
                                                        >
                                                            <Edit size={16} />
                                                        </button>
                                                        <button
                                                            onClick={() => handleTestFlow(flow._id)}
                                                            className="text-green-600 hover:text-green-900"
                                                            title="Test Flow"
                                                        >
                                                            <Play size={16} />
                                                        </button>
                                                        <button
                                                            onClick={() => handleDeleteFlow(flow._id)}
                                                            className="text-red-600 hover:text-red-900"
                                                            title="Delete Flow"
                                                        >
                                                            <Trash2 size={16} />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Pagination */}
                            <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                                <div className="flex-1 flex justify-between sm:hidden">
                                    <button
                                        onClick={() => setPagination(prev => ({
                                            ...prev,
                                            offset: Math.max(0, prev.offset - prev.limit)
                                        }))}
                                        disabled={pagination.offset === 0}
                                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                    >
                                        Previous
                                    </button>
                                    <button
                                        onClick={() => setPagination(prev => ({
                                            ...prev,
                                            offset: prev.offset + prev.limit
                                        }))}
                                        disabled={pagination.offset + pagination.limit >= pagination.total}
                                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </main>
            <FloatingChatWindow
                flowId={selectedFlowId}
                isOpen={isTestModalOpen}
                onClose={() => {
                    setIsTestModalOpen(false);
                    setSelectedFlowId(null);
                }}
                onSave={() => {
                    // Handle save if needed
                    console.log('Save flow before testing');
                }}
            />
        </div>
    );
};

export default FlowList;