//src/components/flows/ChatFlowBuilder.js
//http://localhost:3000/flows?flowId=6745e2078826b553616d9b2d
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import ReactFlow, {
    addEdge,
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    Panel,
    Handle,
    Position,
    useKeyPress,
} from 'reactflow';
import 'reactflow/dist/style.css';
import {
    MessageSquare,
    Save,
    Link,
    ArrowRight,
    Image,
    FileText,
    Clock,
    List,
    PhoneCall,
    Map,
    Template,
    Video,
    Mic,
    Calendar,
    Timer,
    Variable,
    Clock8,
    Workflow,
} from 'lucide-react';
import {
    Tooltip, TooltipTrigger, TooltipContent, TooltipProvider
} from '../ui/tooltip';
import { apiService } from '../../services/api';
import ActionNodeProperties from './ActionNodeProperties';
import InputCollectionProperties from './InputCollectionProperties';
import FlowSaveDialog from './FlowSaveDialog';
import FloatingChatWindow from './FloatingChatWindow';
import FlowSelectionProperties from './FlowSelectionProperties';




// Custom Node Types with Handles

// Start Node
const StartNode = () => {
    return (
        <div className="bg-white rounded-lg shadow-lg p-4 border-2 border-gray-500 min-w-[150px]">
            <div className="flex items-center gap-2 mb-2 text-gray-600 font-medium justify-center">
                <div className="w-3 h-3 bg-green-500 rounded-full animate-pulse" />
                <span>Start</span>
            </div>
            <Handle
                type="source"
                position={Position.Right}
                className="w-3 h-3 bg-gray-500"
            />
        </div>
    );
};

const FlowSelectionNode = ({ data }) => {
    //console.log('FlowSelectionNode', 'data:', data);
    return (
        <div className="bg-white rounded-lg shadow-lg p-4 border-2 border-amber-500 min-w-[200px]">
            <Handle type="target" position={Position.Top} className="w-3 h-3 bg-amber-500" />
            <div className="flex items-center gap-2 mb-2 text-amber-600 font-medium">
                <Workflow size={16} />
                <span>Flow Selection</span>
            </div>
            <div className="text-sm text-gray-600">
                {data.targetFlow ? (
                    <div className="flex flex-col gap-1">
                        <div className="font-medium">{data.targetFlowName}</div>
                        <div className="text-xs text-gray-500">
                            {data.targetFlow ? data.targetFlow : 'No flow selected'}
                        </div>
                    </div>
                ) : (
                    'Select target flow'
                )}
            </div>
            <Handle type="source" position={Position.Bottom} className="w-3 h-3 bg-amber-500" />
        </div>
    );
};

//setvariable node
const SetVariableNode = ({ data }) => {
    return (
        <div className="bg-white rounded-lg shadow-lg p-4 border-2 border-indigo-500 min-w-[200px]">
            <Handle type="target" position={Position.Top} className="w-3 h-3 bg-indigo-500" />
            <div className="flex items-center gap-2 mb-2 text-indigo-600 font-medium">
                <Variable size={16} />
                <span>Set Variable</span>
            </div>
            <div className="text-sm text-gray-600">
                {data.variableName ? (
                    <div className="flex flex-col gap-1">
                        <div className="font-medium">{data.variableName}</div>
                        <div className="text-xs text-gray-500">
                            {data.valueType === 'static' ? (
                                `= ${data.value}`
                            ) : (
                                `= ${data.expression || 'Expression'}`
                            )}
                        </div>
                    </div>
                ) : (
                    'Configure variable'
                )}
            </div>
            <Handle type="source" position={Position.Bottom} className="w-3 h-3 bg-indigo-500" />
        </div>
    );
};


// Message Node
const MessageNode = ({ data, selected }) => {
    const truncateMessage = (message) => {
        if (!message) return 'Enter message here';
        const words = message.split(' ');
        if (words.length <= 2) return message;
        return `${words.slice(0, 2).join(' ')}...`;
    };

    return (
        <div className={`
                bg-white rounded-lg shadow-lg p-4 border-2 border-blue-500 min-w-[200px]
                transition-all duration-300
                ${selected ? 'ring-2 ring-blue-500 ring-offset-2' : ''}
            `}>
            <Handle type="target" position={Position.Top} className="w-3 h-3 bg-blue-500" />
            <div className="flex items-center gap-2 mb-2 text-blue-600 font-medium">
                <MessageSquare size={16} />
                <span>Message</span>
                {data.waitForInput && (
                    <Clock8 size={14} className="text-yellow-500" title="Waiting for input" />
                )}
            </div>
            <div className="text-sm text-gray-600 truncate" title={data.message || 'Enter message here'}>
                {truncateMessage(data.message)}
            </div>
            <Handle type="source" position={Position.Bottom} className="w-3 h-3 bg-blue-500" />
        </div>
    );
};

const ActionNode = ({ data }) => {
    //console.log("ActionData", data);
    return (
        <div className="bg-white rounded-lg shadow-lg p-4 border-2 border-green-500 min-w-[200px]">
            <Handle type="target" position={Position.Top} className="w-3 h-3 bg-green-500" />
            <div className="flex items-center gap-2 mb-2 text-green-600 font-medium">
                <Link size={16} />
                <span>Action</span>
            </div>
            <div className="text-sm text-gray-600">
                {data.actionType || 'Configure action'}
                {/*if functionName is present then show it*/}
                {data.functionName && (
                    <div className="text-xs text-gray-500">
                        {data.functionName}
                    </div>
                )}
            </div>
            <Handle type="source" position={Position.Bottom} className="w-3 h-3 bg-green-500" />
        </div>
    );
};

const ConditionNode = ({ data }) => {
    return (
        <div className="relative bg-white rounded-lg shadow-lg p-4 border-2 border-purple-500 min-w-[200px]">
            <Handle type="target" position={Position.Top} className="w-3 h-3 bg-purple-500" />

            <div className="flex items-center gap-2 mb-2 text-purple-600 font-medium">
                <ArrowRight size={16} />
                <span>Condition</span>
            </div>

            <div className="text-sm text-gray-600">
                {data.condition || 'Set condition'}
            </div>

            <Handle type="source" position={Position.Bottom} className="w-3 h-3 bg-purple-500" />

            {/* True handle with label */}
            <div className="absolute right-0 top-[35%] flex items-center">
                <span className="mr-2 text-xs font-medium text-green-600">True</span>
                <Handle
                    type="source"
                    position={Position.Right}
                    id="true"
                    className="w-3 h-3 bg-green-500"
                    style={{ right: -6 }}
                />
            </div>

            {/* False handle with label */}
            <div className="absolute right-0 top-[65%] flex items-center">
                <span className="mr-2 text-xs font-medium text-red-600">False</span>
                <Handle
                    type="source"
                    position={Position.Right}
                    id="false"
                    className="w-3 h-3 bg-red-500"
                    style={{ right: -6 }}
                />
            </div>

            {/* Visual separator between true/false paths */}
            <div className="absolute right-8 top-[35%] bottom-[35%] w-px bg-gray-200" />
        </div>
    );
};


// Media Message Node
const MediaNode = ({ data }) => {
    const getIcon = () => {
        switch (data.mediaType) {
            case 'image': return <Image size={16} />;
            case 'video': return <Video size={16} />;
            case 'audio': return <Mic size={16} />;
            case 'document': return <FileText size={16} />;
            default: return <FileText size={16} />;
        }
    };

    return (
        <div className="bg-white rounded-lg shadow-lg p-4 border-2 border-pink-500 min-w-[200px]">
            <Handle type="target" position={Position.Top} className="w-3 h-3 bg-pink-500" />
            <div className="flex items-center gap-2 mb-2 text-pink-600 font-medium">
                {getIcon()}
                <span>Media Message</span>
                {data.waitForInput && (
                    <Clock8 size={14} className="text-yellow-500" title="Waiting for input" />
                )}
            </div>
            <div className="text-sm text-gray-600">
                {data.caption || 'Add caption'}
            </div>
            <div className="mt-2 text-xs text-gray-500">
                Type: {data.mediaType || 'Select type'}
                {data.mediaUrl && (
                    <div className="truncate max-w-[180px]">
                        URL: {data.mediaUrl}
                    </div>
                )}
            </div>
            <Handle type="source" position={Position.Bottom} className="w-3 h-3 bg-pink-500" />
        </div>
    );
};

// Quick Reply Node
const QuickReplyNode = ({ data }) => {
    const options = data.options || [];
    const totalHeight = 100; // Base height for the node
    const optionSpacing = options.length > 0 ? totalHeight / (options.length) : 0;

    return (
        <div className="bg-white rounded-lg shadow-lg p-4 border-2 border-orange-500 min-w-[200px]">
            <Handle type="target" position={Position.Top} className="w-3 h-3 bg-orange-500" />
            <div className="flex items-center gap-2 mb-2 text-orange-600 font-medium">
                <List size={16} />
                <span>Quick Replies</span>
                {data.waitForInput && (
                    <Clock8 size={14} className="text-yellow-500" title="Waiting for input" />
                )}
            </div>
            <div className="text-sm text-gray-600">
                {data.question || 'Add question'}
            </div>
            <div className="mt-2 space-y-1">
                {options.map((option, index) => (
                    <div key={index} className="text-xs text-gray-500">
                        {option}
                    </div>
                ))}
            </div>

            {/* Option Handles with Labels */}
            {options.map((option, index) => (
                <div
                    key={index}
                    className="absolute right-0 flex items-center"
                    style={{
                        top: `${(index + 1) * optionSpacing - 10}%`,
                        transform: 'translateY(-50%)'
                    }}
                >
                    <span className="mr-2 text-xs font-medium text-orange-600">
                        {option}
                    </span>
                    <Handle
                        type="source"
                        position={Position.Right}
                        id={`option-${index}`}
                        className="w-3 h-3 bg-orange-500"
                        style={{ right: -6 }}
                    />
                </div>
            ))}
        </div>
    );
};

// Location Node
const LocationNode = ({ data }) => {
    return (
        <div className="bg-white rounded-lg shadow-lg p-4 border-2 border-cyan-500 min-w-[200px]">
            <Handle type="target" position={Position.Top} className="w-3 h-3 bg-cyan-500" />
            <div className="flex items-center gap-2 mb-2 text-cyan-600 font-medium">
                <Map size={16} />
                <span>Location</span>
            </div>
            <div className="text-sm text-gray-600">
                {data.name || 'Location name'}
            </div>
            <div className="mt-2 text-xs text-gray-500">
                {data.address || 'Add address'}
            </div>
            <Handle type="source" position={Position.Bottom} className="w-3 h-3 bg-cyan-500" />
        </div>
    );
};

// Delay Node
const DelayNode = ({ data }) => {
    return (
        <div className="bg-white rounded-lg shadow-lg p-4 border-2 border-yellow-500 min-w-[200px]">
            <Handle type="target" position={Position.Top} className="w-3 h-3 bg-yellow-500" />
            <div className="flex items-center gap-2 mb-2 text-yellow-600 font-medium">
                <Timer size={16} />
                <span>Delay</span>
            </div>
            <div className="text-sm text-gray-600">
                {data.duration || 'Set delay'} {data.unit || 'seconds'}
            </div>
            <Handle type="source" position={Position.Bottom} className="w-3 h-3 bg-yellow-500" />
        </div>
    );
};

// Input Collection Node
const InputCollectionNode = ({ data }) => {
    return (
        <div className="bg-white rounded-lg shadow-lg p-4 border-2 border-violet-500 min-w-[200px]">
            <Handle type="target" position={Position.Top} className="w-3 h-3 bg-violet-500" />
            <div className="flex items-center gap-2 mb-2 text-violet-600 font-medium">
                <FileText size={16} />
                <span>Input Collection</span>
                {data.waitForInput && (
                    <Clock8 size={14} className="text-yellow-500" title="Waiting for input" />
                )}
            </div>
            <div className="text-sm text-gray-600">
                {data.question || 'Enter question'}
            </div>
            <div className="mt-2 text-xs text-gray-500">
                Type: {data.inputType || 'text'}
                {data.validation && <div>Validation: {data.validation}</div>}
            </div>
            <Handle type="source" position={Position.Bottom} className="w-3 h-3 bg-violet-500" />
        </div>
    );
};

// API Integration Node
const APINode = ({ data }) => {
    return (
        <div className="bg-white rounded-lg shadow-lg p-4 border-2 border-emerald-500 min-w-[200px]">
            <Handle type="target" position={Position.Top} className="w-3 h-3 bg-emerald-500" />
            <div className="flex items-center gap-2 mb-2 text-emerald-600 font-medium">
                <Link size={16} />
                <span>API Integration</span>
            </div>
            <div className="text-sm text-gray-600">
                {data.endpoint || 'Configure API'}
            </div>
            <div className="mt-2 text-xs text-gray-500">
                Method: {data.method || 'GET'}
            </div>
            <Handle type="source" position={Position.Bottom} className="w-3 h-3 bg-emerald-500" />
            <Handle
                type="source"
                position={Position.Right}
                id="success"
                className="w-3 h-3 bg-green-500"
                style={{ top: '35%' }}
            />
            <Handle
                type="source"
                position={Position.Right}
                id="error"
                className="w-3 h-3 bg-red-500"
                style={{ top: '65%' }}
            />
        </div>
    );
};

// Scheduled Node
const ScheduledNode = ({ data }) => {
    return (
        <div className="bg-white rounded-lg shadow-lg p-4 border-2 border-blue-500 min-w-[200px]">
            <Handle type="target" position={Position.Top} className="w-3 h-3 bg-blue-500" />
            <div className="flex items-center gap-2 mb-2 text-blue-600 font-medium">
                <Calendar size={16} />
                <span>Scheduled</span>
            </div>
            <div className="text-sm text-gray-600">
                {data.schedule || 'Set schedule'}
            </div>
            <div className="mt-2 text-xs text-gray-500">
                {data.timezone || 'UTC'}
            </div>
            <Handle type="source" position={Position.Bottom} className="w-3 h-3 bg-blue-500" />
        </div>
    );
};



const nodeTypes = {
    message: MessageNode,
    action: ActionNode,
    condition: ConditionNode,
    media: MediaNode,
    quickReply: QuickReplyNode,
    location: LocationNode,
    delay: DelayNode,
    inputCollection: InputCollectionNode,
    api: APINode,
    scheduled: ScheduledNode,
    start: StartNode,
    setVariable: SetVariableNode,
    flowSelection: FlowSelectionNode,
};

const ChatFlowBuilder = () => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [selectedNode, setSelectedNode] = useState(null);
    const [searchParams] = useSearchParams();
    const [isChatOpen, setIsChatOpen] = useState(false);
    const deleteKeyPressed = useKeyPress('Delete');

    // Add this to the onConnect callback in ChatFlowBuilder
    const onConnect = useCallback(
        (params) => {
            // Check if the connection is from a condition node
            const sourceHandle = params.sourceHandle;
            let style = {
                stroke: '#888',
                strokeWidth: 2,
                transition: 'all 0.3s ease',
                cursor: 'pointer'
            };

            // Style edges differently based on condition output
            if (sourceHandle === 'true') {
                style = {
                    ...style,
                    stroke: '#22c55e',  // Green color for true path
                };
            } else if (sourceHandle === 'false') {
                style = {
                    ...style,
                    stroke: '#ef4444',  // Red color for false path
                };
            }

            const edge = {
                ...params,
                type: 'smoothstep',
                animated: true,
                style,
                labelBgStyle: { fill: '#ffffff' },
                labelStyle: { fill: '#000000' },
                label: sourceHandle === 'true' ? 'Yes' : sourceHandle === 'false' ? 'No' : '',
            };

            setEdges((eds) => addEdge(edge, eds));
        },
        [setEdges]
    );

    // Add this new helper function
    const getEdgeStyle = useCallback((edge) => {
        const baseStyle = {
            strokeWidth: edge.selected ? 3 : 2,
            transition: 'all 0.3s ease',
            cursor: 'pointer'
        };

        // Preserve condition edge colors
        if (edge.sourceHandle === 'true') {
            return {
                ...baseStyle,
                stroke: edge.selected ? '#16a34a' : '#22c55e', // Darker green when selected
            };
        } else if (edge.sourceHandle === 'false') {
            return {
                ...baseStyle,
                stroke: edge.selected ? '#dc2626' : '#ef4444', // Darker red when selected
            };
        }

        // Default edge color
        return {
            ...baseStyle,
            stroke: edge.selected ? '#3b82f6' : '#888', // Blue when selected, gray when not
        };
    }, []);

    // Add keyboard handler for Delete key
    const onKeyDown = useCallback((event) => {
        if (event.key === 'Delete') {
            setEdges((eds) => eds.filter((edge) => !edge.selected));
        }
    }, []);

    // Add effect for keyboard handler
    useEffect(() => {
        const onKeyDown = (event) => {
            if (event.key === 'Delete') {
                setNodes((nds) => nds.filter((node) =>
                    !node.selected || node.type === 'start'
                ));
                setEdges((eds) => eds.filter((edge) => !edge.selected));
            }
        };

        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [setNodes, setEdges]);

    // Update edges with styles
    const edgesWithStyles = useMemo(() => {
        return edges.map(edge => ({
            ...edge,
            style: getEdgeStyle(edge),
        }));
    }, [edges, getEdgeStyle]);



    // Initialize the flow with a start node
    useEffect(() => {
        if (nodes.length === 0) {
            const startNode = {
                id: 'start-node',
                type: 'start',
                position: { x: 400, y: 50 }, // Position it at the top center
                data: {},
                deletable: false, // Prevent deletion of start node
            };
            setNodes([startNode]);
        }
    }, [nodes, setNodes]);

    useEffect(() => {
        if (deleteKeyPressed) {
            setNodes((nds) => nds.filter((node) => !node.selected || node.type === 'start'));
            setEdges((eds) => eds.filter((edge) => !edge.selected));
        }
    }, [deleteKeyPressed]);

    //node styles
    const getNodeStyle = (selected) => {
        return {
            boxShadow: selected ? '0 0 0 2px #3b82f6' : 'none',
            transition: 'all 0.1s ease',
            border: selected ? '1px solid #3b82f6' : undefined,
            borderRadius: selected ? '8px' : '0px',
        };
    };

    const nodesWithStyles = useMemo(() => {
        return nodes.map(node => ({
            ...node,
            style: {
                ...node.style,
                ...getNodeStyle(node.selected)
            },
        }));
    }, [nodes]);



    // Load flow based on query parameter
    useEffect(() => {
        const loadFlow = async (flowId) => {
            try {
                const response = await apiService.getFlow(flowId);
                setFlowData({
                    id: response._id,
                    name: response.name,
                    description: response.description,
                    version: response.version
                });

                // Set nodes and edges in the format ReactFlow expects
                setNodes(response.nodes.map(node => ({
                    ...node,
                    position: {
                        x: node.position.x,
                        y: node.position.y
                    }
                })));
                setEdges(response.edges);
            } catch (error) {
                console.error('Error loading flow:', error);
                // You might want to show an error message to the user
                // showToast('Error loading flow: ' + error.message, 'error');
            }
        };

        // Get flowId from query string
        const flowId = searchParams.get('flowId');
        if (flowId) {
            loadFlow(flowId);
        }
    }, [searchParams, setNodes, setEdges]);

    // Add node positioning logic to place new nodes below the start node
    const addNode = (type) => {
        const position = {
            x: Math.random() * window.innerWidth / 2,
            y: Math.random() * window.innerHeight / 2 + 100, // Add offset to avoid overlapping with start node
        };

        const newNode = {
            id: `${type}-${Date.now()}`,
            type,
            position,
            data: { label: `New ${type}` },
        };

        setNodes((nds) => nds.concat(newNode));
    };



    const onNodeClick = (event, node) => {
        setSelectedNode(node);
    };

    const updateNodeData = (data) => {
        if (!selectedNode) return;

        setNodes((nds) =>
            nds.map((node) => {
                if (node.id === selectedNode.id) {
                    // Create a new data object preserving existing data
                    const newData = {
                        ...node.data,
                        ...data,
                    };
                    return {
                        ...node,
                        data: newData,
                    };
                }
                return node;
            })
        );
        // Update selected node to reflect changes immediately
        setSelectedNode({
            ...selectedNode,
            data: {
                ...selectedNode.data,
                ...data,
            },
        });
    };

    const updateConditionData = (newData) => {
        const currentData = selectedNode.data || {};
        const updatedData = { ...currentData, ...newData };

        // Build the condition expression based on all data
        const conditionExpression = buildConditionExpression(updatedData);

        updateNodeData({
            ...updatedData,
            condition: conditionExpression
        });
    };

    const buildConditionExpression = (data) => {
        const { variable, operator, value, minValue, maxValue, customVariable } = data;

        if (!variable || !operator) return 'Incomplete condition';

        // Handle custom variable
        const variableName = variable === 'custom' ? customVariable : variable;
        if (variable === 'custom' && !customVariable) {
            return 'Please enter custom variable name';
        }

        // Build expression based on operator type
        switch (operator) {
            case 'exists':
                return `${variableName} exists`;
            case 'not_exists':
                return `${variableName} does not exist`;
            case 'between':
                if (!minValue || !maxValue) return 'Please enter both min and max values';
                return `${variableName} is between ${minValue} and ${maxValue}`;
            case 'contains':
                return `${variableName} contains "${value}"`;
            case 'not_contains':
                return `${variableName} does not contain "${value}"`;
            case 'starts_with':
                return `${variableName} starts with "${value}"`;
            case 'ends_with':
                return `${variableName} ends with "${value}"`;
            case 'equals':
                return `${variableName} = "${value}"`;
            case 'not_equals':
                return `${variableName} ≠ "${value}"`;
            case 'greater_than':
                return `${variableName} > ${value}`;
            case 'less_than':
                return `${variableName} < ${value}`;
            case 'greater_equals':
                return `${variableName} ≥ ${value}`;
            case 'less_equals':
                return `${variableName} ≤ ${value}`;
            default:
                return 'Invalid operator';
        }
    };

    /*
    const saveFlow = () => {
        const flow = {
            nodes,
            edges,
        };
        console.log('Saving flow:', flow);
        apiService.saveFlow(flow);
        // Here you would typically save to your backend
    };
    */

    const [flowData, setFlowData] = useState({
        id: null,
        name: 'New Flow',
        description: ''
    });
    /*
    const handleSaveFlow = async (metadata) => {
        const flow = {
            name: metadata.name,
            description: metadata.description,
            nodes,
            edges,
        };

        try {
            let response;
            if (flowData.id) {
                // Update existing flow
                response = await apiService.updateFlow(flowData.id, flow);
            } else {
                // Save new flow
                response = await apiService.saveFlow(flow);
            }

            // Update local flow data with server response
            setFlowData({
                id: response._id,
                name: response.name,
                description: response.description
            });

            // Show success message (you can implement this based on your UI needs)
            // showToast('Flow saved successfully');
        } catch (error) {
            console.error('Error saving flow:', error);
            // Show error message
            // showToast('Error saving flow', 'error');
        }
    };
    */


    const handleSaveFlow = async (metadata) => {
        // Clean nodes by removing runtime properties and ensuring correct format
        const cleanedNodes = nodes.map(node => ({
            id: node.id,
            type: node.type,
            position: {
                x: node.position.x,
                y: node.position.y
            },
            data: node.data || {},
            width: node.width,
            height: node.height,
            deletable: node.deletable
        }));

        // Clean edges by ensuring correct format
        const cleanedEdges = edges.map(edge => ({
            id: edge.id,
            source: edge.source,
            sourceHandle: edge.sourceHandle,
            target: edge.target,
            targetHandle: edge.targetHandle,
            type: edge.type || 'smoothstep',
            animated: edge.animated || true,
            style: edge.style || { stroke: '#888' },
            label: edge.label || ''
        }));

        const flow = {
            name: metadata.name,
            description: metadata.description,
            nodes: cleanedNodes,
            edges: cleanedEdges,
            version: flowData.version || 1
        };

        try {
            let response;
            if (flowData.id) {
                // Update existing flow
                response = await apiService.updateFlow(flowData.id, flow);
            } else {
                // Save new flow
                response = await apiService.saveFlow(flow);
            }

            // Update local flow data with server response
            setFlowData({
                id: response._id,
                name: response.name,
                description: response.description,
                version: response.version
            });

            // Show success message if you have a toast system
            // showToast('Flow saved successfully');
        } catch (error) {
            console.error('Error saving flow:', error);
            // Show error message
            // showToast('Error saving flow: ' + error.message, 'error');
        }
    };

    const renderWaitForInputCheckbox = () => {
        if (!selectedNode || selectedNode.type === 'start') return null;

        return (
            <div className="mt-4 pt-4 border-t">
                <label className="flex items-center space-x-2 text-sm text-gray-700">
                    <input
                        type="checkbox"
                        checked={selectedNode.data?.waitForInput || false}
                        onChange={(e) => updateNodeData({ waitForInput: e.target.checked })}
                        className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                    <span>Wait for user input before proceeding</span>
                </label>
                {selectedNode.data?.waitForInput && (
                    <p className="mt-1 text-xs text-gray-500">
                        Flow execution will pause at this node until user input is received
                    </p>
                )}
            </div>
        );
    };

    // Default connection settings
    const defaultEdgeOptions = {
        animated: true,
        type: 'smoothstep',
        style: { strokeWidth: 2 },
        className: 'transition-colors duration-300',
    };

    ///////////START EDFGE EDITING ///////

    return (
        <div className="h-screen flex">
            {/* Main Flow Area */}
            <div className="flex-1 h-full">
                <ReactFlow
                    nodes={nodesWithStyles}
                    edges={edgesWithStyles}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onNodeClick={onNodeClick}
                    nodeTypes={nodeTypes}
                    defaultEdgeOptions={defaultEdgeOptions}
                    fitView
                    snapToGrid
                    snapGrid={[15, 15]}
                    selectNodesOnDrag={false}
                    edgesUpdatable={true}
                    edgesFocusable={true}
                    elementsSelectable={true}
                    multiSelectionKeyCode="Shift"
                >
                    <Controls />
                    <MiniMap />
                    <Background variant="dots" gap={12} size={1} />

                    {/* Vertical Toolbar */}
                    <Panel position="top-left" className="bg-white p-2 rounded-lg shadow-lg">
                        <div className="flex flex-col gap-2">
                            {/* Message Node */}
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <button
                                            onClick={() => addNode('message')}
                                            className="flex items-center gap-2 px-4 py-2 bg-blue-100 text-blue-600 rounded hover:bg-blue-200 transition-colors w-full"
                                        >
                                            <MessageSquare size={16} />
                                        </button>
                                    </TooltipTrigger>
                                    <TooltipContent side="right">
                                        <p>Message</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                            {/* FlowSelection Node */}
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <button
                                            onClick={() => addNode('flowSelection')}
                                            className="flex items-center gap-2 px-4 py-2 bg-amber-100 text-amber-600 rounded hover:bg-amber-200 transition-colors w-full"
                                        >
                                            <Workflow size={16} />

                                        </button>
                                    </TooltipTrigger>
                                    <TooltipContent side="right">
                                        <p>Flow Selection</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>

                            {/* Action Node */}
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <button
                                            onClick={() => addNode('action')}
                                            className="flex items-center gap-2 px-4 py-2 bg-green-100 text-green-600 rounded hover:bg-green-200 transition-colors w-full"
                                        >
                                            <Link size={16} />
                                            <span className="text-sm"></span>
                                        </button>
                                    </TooltipTrigger>
                                    <TooltipContent side="right">
                                        <p>Actions</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>


                            {/* Set Variable Node */}


                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <button
                                            onClick={() => addNode('setVariable')}
                                            className="flex items-center gap-2 px-4 py-2 bg-indigo-100 text-indigo-600 rounded hover:bg-indigo-200 transition-colors w-full"
                                        >
                                            <Variable size={16} />
                                        </button>
                                    </TooltipTrigger>
                                    <TooltipContent side="right">
                                        <p>Set Variable</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>

                            {/* Condition Node */}


                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <button
                                            onClick={() => addNode('condition')}
                                            className="flex items-center gap-2 px-4 py-2 bg-purple-100 text-purple-600 rounded hover:bg-purple-200 transition-colors w-full"
                                        >
                                            <ArrowRight size={16} />
                                        </button>
                                    </TooltipTrigger>
                                    <TooltipContent side="right">
                                        <p>Condition</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>

                            {/* Media Node */}

                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <button
                                            onClick={() => addNode('media')}
                                            className="flex items-center gap-2 px-4 py-2 bg-pink-100 text-pink-600 rounded hover:bg-pink-200 transition-colors w-full"
                                        >
                                            <Image size={16} />
                                        </button>
                                    </TooltipTrigger>
                                    <TooltipContent side="right">
                                        <p>Media</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>

                            {/* Input Collection Node */}

                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <button
                                            onClick={() => addNode('inputCollection')}
                                            className="flex items-center gap-2 px-4 py-2 bg-orange-100 text-orange-600 rounded hover:bg-orange-200 transition-colors w-full"
                                        >
                                            <FileText size={16} />
                                        </button>
                                    </TooltipTrigger>
                                    <TooltipContent side="right">
                                        <p>Input</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>


                            {/* Quick Reply Node */}


                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <button
                                            onClick={() => addNode('quickReply')}
                                            className="flex items-center gap-2 px-4 py-2 bg-yellow-100 text-yellow-600 rounded hover:bg-yellow-200 transition-colors w-full"
                                        >
                                            <List size={16} />
                                        </button>
                                    </TooltipTrigger>
                                    <TooltipContent side="right">
                                        <p>Quick Reply</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>

                            {/* Delay Node 
                            <button
                                onClick={() => addNode('delay')}
                                className="flex items-center gap-2 px-4 py-2 bg-indigo-100 text-indigo-600 rounded hover:bg-indigo-200 transition-colors w-full"
                            >
                                <Timer size={16} />
                                <span className="text-sm">Delay</span>
                            </button>
                            */}

                            {/* Location Node */}


                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <button
                                            onClick={() => addNode('location')}
                                            className="flex items-center gap-2 px-4 py-2 bg-cyan-100 text-cyan-600 rounded hover:bg-cyan-200 transition-colors w-full"
                                        >
                                            <Map size={16} />
                                        </button>
                                    </TooltipTrigger>
                                    <TooltipContent side="right">
                                        <p>Location</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>


                            {/* API Node 
                            <button
                                onClick={() => addNode('api')}
                                className="flex items-center gap-2 px-4 py-2 bg-emerald-100 text-emerald-600 rounded hover:bg-emerald-200 transition-colors w-full"
                            >
                                <Link size={16} />
                                <span className="text-sm">API</span>
                            </button>
                            */}

                            {/* Save Flow button at the bottom */}
                            <div className="mt-auto pt-2 border-t">

                                <FlowSaveDialog
                                    flow={flowData}
                                    onSave={handleSaveFlow}
                                />
                            </div>
                            {/* Test Flow button */}
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <button
                                            onClick={() => setIsChatOpen(true)}
                                            className="flex items-center gap-2 px-4 py-2 bg-green-100 text-green-600 rounded hover:bg-green-200 transition-colors w-full"
                                        >
                                            <MessageSquare size={16} />
                                        </button>
                                    </TooltipTrigger>
                                    <TooltipContent side="right">
                                        <p>Test Flow</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>

                        </div>
                    </Panel>


                </ReactFlow>

            </div>

            {/* Properties Panel */}
            {
                selectedNode && (
                    <div className="w-80 bg-white border-l p-4">
                        <h3 className="text-lg font-medium mb-4">Node Properties</h3>
                        {selectedNode.type === 'message' && (
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Message Text</label>
                                    <textarea
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        rows={4}
                                        value={selectedNode.data.message || ''}
                                        onChange={(e) => updateNodeData({ message: e.target.value })}
                                        placeholder="Enter message content..."
                                    />
                                </div>
                                {renderWaitForInputCheckbox()}
                            </div>
                        )}
                        {selectedNode.type === 'action' && (
                            <ActionNodeProperties
                                data={selectedNode.data}
                                onChange={(newData) => updateNodeData(newData)}
                            />
                        )}
                        {selectedNode.type === 'condition' && (
                            <div className="space-y-6">
                                {/* Predefined Variables Section */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">Variable</label>
                                    <select
                                        className="w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                                        value={selectedNode.data?.variable || ''}
                                        onChange={(e) => updateConditionData({ variable: e.target.value })}
                                    >
                                        <optgroup label="User Data">
                                            <option value="user.name">User Name</option>
                                            <option value="user.email">User Email</option>
                                            <option value="user.phone">User Phone</option>
                                        </optgroup>
                                        <optgroup label="Message Data">
                                            <option value="message.text">Message Text</option>
                                            <option value="message.type">Message Type</option>
                                        </optgroup>
                                        <optgroup label="System Variables">
                                            <option value="current.time">Current Time</option>
                                            <option value="current.date">Current Date</option>
                                        </optgroup>
                                        <optgroup label="Custom Variables">
                                            <option value="custom">Custom Variable</option>
                                        </optgroup>
                                    </select>
                                </div>

                                {/* Custom Variable Input */}
                                {selectedNode.data?.variable === 'custom' && (
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            Custom Variable Name
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                                            value={selectedNode.data?.customVariable || ''}
                                            onChange={(e) => updateConditionData({ customVariable: e.target.value })}
                                            placeholder="Enter variable name..."
                                        />
                                    </div>
                                )}

                                {/* Operator Selection */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">Operator</label>
                                    <select
                                        className="w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                                        value={selectedNode.data?.operator || ''}
                                        onChange={(e) => updateConditionData({ operator: e.target.value })}
                                    >
                                        <optgroup label="Comparison">
                                            <option value="equals">Equals (=)</option>
                                            <option value="not_equals">Not Equals (≠)</option>
                                            <option value="greater_than">Greater Than (&gt;)</option>
                                            <option value="less_than">Less Than (&lt;)</option>
                                            <option value="greater_equals">Greater Than or Equal (≥)</option>
                                            <option value="less_equals">Less Than or Equal (≤)</option>
                                        </optgroup>
                                        <optgroup label="Text">
                                            <option value="contains">Contains</option>
                                            <option value="not_contains">Does Not Contain</option>
                                            <option value="starts_with">Starts With</option>
                                            <option value="ends_with">Ends With</option>
                                        </optgroup>
                                        <optgroup label="Existence">
                                            <option value="exists">Exists</option>
                                            <option value="not_exists">Does Not Exist</option>
                                        </optgroup>
                                        <optgroup label="Range">
                                            <option value="between">Between</option>
                                        </optgroup>
                                    </select>
                                </div>

                                {/* Value Input - Show based on operator type */}
                                {selectedNode.data?.operator &&
                                    !['exists', 'not_exists'].includes(selectedNode.data.operator) && (
                                        <div>
                                            {selectedNode.data.operator === 'between' ? (
                                                <div className="space-y-3">
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                                            Minimum Value
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                                                            value={selectedNode.data?.minValue || ''}
                                                            onChange={(e) => updateConditionData({ minValue: e.target.value })}
                                                            placeholder="Enter minimum value..."
                                                        />
                                                    </div>
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                                            Maximum Value
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                                                            value={selectedNode.data?.maxValue || ''}
                                                            onChange={(e) => updateConditionData({ maxValue: e.target.value })}
                                                            placeholder="Enter maximum value..."
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700 mb-2">Value</label>
                                                    <input
                                                        type="text"
                                                        className="w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                                                        value={selectedNode.data?.value || ''}
                                                        onChange={(e) => updateConditionData({ value: e.target.value })}
                                                        placeholder="Enter value..."
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}

                                {/* Preview Section */}
                                <div className="pt-4 border-t">
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Condition Preview
                                    </label>
                                    <div className="p-3 bg-gray-50 rounded-md text-sm text-gray-600 font-mono break-words">
                                        {buildConditionExpression(selectedNode.data)}
                                    </div>
                                    <p className="mt-2 text-xs text-gray-500">
                                        This condition will be evaluated at runtime.
                                        {selectedNode.data?.variable === 'custom' &&
                                            " Make sure the custom variable exists in your flow context."}
                                    </p>
                                </div>
                            </div>
                        )}
                        {selectedNode.type === 'media' && (
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Media Type</label>
                                    <select
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        value={selectedNode.data.mediaType || ''}
                                        onChange={(e) => updateNodeData({ mediaType: e.target.value })}
                                    >
                                        <option value="">Select media type...</option>
                                        <option value="image">Image</option>
                                        <option value="video">Video</option>
                                        <option value="audio">Audio</option>
                                        <option value="document">Document</option>
                                    </select>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Media URL</label>
                                    <input
                                        type="url"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        value={selectedNode.data.mediaUrl || ''}
                                        onChange={(e) => updateNodeData({ mediaUrl: e.target.value })}
                                        placeholder="Enter media URL..."
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Caption</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        value={selectedNode.data.caption || ''}
                                        onChange={(e) => updateNodeData({ caption: e.target.value })}
                                        placeholder="Enter caption..."
                                    />
                                </div>
                                {renderWaitForInputCheckbox()}
                            </div>
                        )}

                        {selectedNode.type === 'inputCollection' && (
                            <div>
                                <InputCollectionProperties
                                    data={selectedNode.data}
                                    onChange={(newData) => updateNodeData(newData)}
                                />
                                {renderWaitForInputCheckbox()}
                            </div>
                        )}

                        {/* Location Node Properties */}
                        {selectedNode.type === 'location' && (
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Location Name</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        value={selectedNode.data.name || ''}
                                        onChange={(e) => updateNodeData({ name: e.target.value })}
                                        placeholder="Enter location name..."
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Address</label>
                                    <textarea
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        rows={3}
                                        value={selectedNode.data.address || ''}
                                        onChange={(e) => updateNodeData({ address: e.target.value })}
                                        placeholder="Enter address..."
                                    />
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Latitude</label>
                                        <input
                                            type="number"
                                            step="any"
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                            value={selectedNode.data.latitude || ''}
                                            onChange={(e) => updateNodeData({ latitude: e.target.value })}
                                            placeholder="Latitude..."
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Longitude</label>
                                        <input
                                            type="number"
                                            step="any"
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                            value={selectedNode.data.longitude || ''}
                                            onChange={(e) => updateNodeData({ longitude: e.target.value })}
                                            placeholder="Longitude..."
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Quick Reply Node Properties */}
                        {selectedNode.type === 'quickReply' && (
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Question/Prompt</label>
                                    <textarea
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        rows={3}
                                        value={selectedNode.data.question || ''}
                                        onChange={(e) => updateNodeData({ question: e.target.value })}
                                        placeholder="Enter question or prompt..."
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Reply Options</label>
                                    <div className="mt-1 space-y-2">
                                        {(selectedNode.data.options || []).map((option, index) => (
                                            <div key={index} className="flex gap-2">
                                                <input
                                                    type="text"
                                                    className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    value={option}
                                                    onChange={(e) => {
                                                        const newOptions = [...(selectedNode.data.options || [])];
                                                        newOptions[index] = e.target.value;
                                                        updateNodeData({ options: newOptions });
                                                    }}
                                                    placeholder={`Option ${index + 1}`}
                                                />
                                                <button
                                                    onClick={() => {
                                                        const newOptions = [...(selectedNode.data.options || [])];
                                                        newOptions.splice(index, 1);
                                                        updateNodeData({ options: newOptions });
                                                    }}
                                                    className="p-2 text-red-600 hover:text-red-800"
                                                >
                                                    ×
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                    <button
                                        onClick={() => {
                                            const newOptions = [...(selectedNode.data.options || []), ''];
                                            updateNodeData({ options: newOptions });
                                        }}
                                        className="mt-2 px-4 py-2 text-sm bg-blue-50 text-blue-600 rounded hover:bg-blue-100"
                                    >
                                        Add Option
                                    </button>
                                </div>
                                {renderWaitForInputCheckbox()}
                            </div>
                        )}

                        {/* API Node Properties */}
                        {selectedNode.type === 'api' && (
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Endpoint URL</label>
                                    <input
                                        type="url"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        value={selectedNode.data.endpoint || ''}
                                        onChange={(e) => updateNodeData({ endpoint: e.target.value })}
                                        placeholder="Enter API endpoint..."
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Method</label>
                                    <select
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        value={selectedNode.data.method || 'GET'}
                                        onChange={(e) => updateNodeData({ method: e.target.value })}
                                    >
                                        <option value="GET">GET</option>
                                        <option value="POST">POST</option>
                                        <option value="PUT">PUT</option>
                                        <option value="DELETE">DELETE</option>
                                        <option value="PATCH">PATCH</option>
                                    </select>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Headers</label>
                                    <textarea
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 font-mono text-sm"
                                        rows={4}
                                        value={selectedNode.data.headers || ''}
                                        onChange={(e) => updateNodeData({ headers: e.target.value })}
                                        placeholder='{"Content-Type": "application/json"}'
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Request Body</label>
                                    <textarea
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 font-mono text-sm"
                                        rows={4}
                                        value={selectedNode.data.body || ''}
                                        onChange={(e) => updateNodeData({ body: e.target.value })}
                                        placeholder='{"key": "value"}'
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Success Path Variable</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        value={selectedNode.data.successPath || ''}
                                        onChange={(e) => updateNodeData({ successPath: e.target.value })}
                                        placeholder="response.data.success"
                                    />
                                </div>
                            </div>
                        )}

                        {/* Delay Node Properties */}
                        {selectedNode.type === 'delay' && (
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Duration</label>
                                    <div className="mt-1 grid grid-cols-2 gap-2">
                                        <input
                                            type="number"
                                            min="1"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                            value={selectedNode.data.duration || ''}
                                            onChange={(e) => updateNodeData({ duration: e.target.value })}
                                            placeholder="Enter duration..."
                                        />
                                        <select
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                            value={selectedNode.data.unit || 'seconds'}
                                            onChange={(e) => updateNodeData({ unit: e.target.value })}
                                        >
                                            <option value="seconds">Seconds</option>
                                            <option value="minutes">Minutes</option>
                                            <option value="hours">Hours</option>
                                            <option value="days">Days</option>
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Message (Optional)</label>
                                    <textarea
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        rows={3}
                                        value={selectedNode.data.message || ''}
                                        onChange={(e) => updateNodeData({ message: e.target.value })}
                                        placeholder="Message to show during delay..."
                                    />
                                </div>
                            </div>
                        )}

                        {selectedNode.type === 'setVariable' && (
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Variable Context</label>
                                    <select
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                        value={selectedNode.data.variableContext || 'flow'}
                                        onChange={(e) => updateNodeData({
                                            variableContext: e.target.value,
                                            variableName: '' // Reset variable name when context changes
                                        })}
                                    >
                                        <optgroup label="User Data">
                                            <option value="user">User Context</option>
                                        </optgroup>
                                        <optgroup label="Flow Data">
                                            <option value="flow">Flow Context</option>
                                        </optgroup>
                                        <optgroup label="Message Data">
                                            <option value="message">Message Context</option>
                                        </optgroup>
                                    </select>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Variable Name</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                        value={selectedNode.data.variableName || ''}
                                        onChange={(e) => updateNodeData({ variableName: e.target.value })}
                                        placeholder={`Enter variable name for ${selectedNode.data.variableContext || 'flow'} context...`}
                                    />
                                    <p className="mt-1 text-sm text-gray-500">
                                        Use dot notation for nested variables (e.g., "user.profile.name")
                                    </p>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Value Type</label>
                                    <select
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                        value={selectedNode.data.valueType || 'static'}
                                        onChange={(e) => updateNodeData({ valueType: e.target.value })}
                                    >
                                        <option value="static">Static Value</option>
                                        <option value="expression">Expression</option>
                                    </select>
                                </div>

                                {selectedNode.data.valueType === 'static' ? (
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Value</label>
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                            value={selectedNode.data.value || ''}
                                            onChange={(e) => updateNodeData({ value: e.target.value })}
                                            placeholder="Enter value..."
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Expression</label>
                                        <textarea
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                            rows={3}
                                            value={selectedNode.data.expression || ''}
                                            onChange={(e) => updateNodeData({ expression: e.target.value })}
                                            placeholder="Enter expression (e.g., user.firstName + ' ' + user.lastName)"
                                        />
                                        <p className="mt-1 text-sm text-gray-500">
                                            You can use variables, operators, and basic JavaScript expressions
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}

                        {/* Flow Selection Node Properties */}
                        {selectedNode.type === 'flowSelection' && (
                            <FlowSelectionProperties
                                data={selectedNode.data}
                                onChange={(newData) => updateNodeData(newData)}
                            />
                        )}

                    </div>
                )
            }
            {/* Floating Chat Window */}
            <FloatingChatWindow
                isOpen={isChatOpen}
                onClose={() => setIsChatOpen(false)}
                flowId={flowData.id}
                onSave={() => {
                    setIsChatOpen(false);
                    // Trigger the save dialog
                    document.querySelector('[data-testid="save-flow-button"]')?.click();
                }}
            />
        </div >
    );
};

export default ChatFlowBuilder;