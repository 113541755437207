
//src/services/api.js
//const API_BASE_URL = process.env.REACT_APP_API_URL || '';
//window._env_ check for environment variables
console.log('API_BASE_URL:', window._env_ ? window._env_.API_URL : process.env.REACT_APP_API_URL);
console.log('API_BASE_URL:', window._env_);
const API_BASE_URL = window._env_ ? window._env_.API_URL : process.env.REACT_APP_API_URL;


class ApiService {
    constructor() {
        this.baseUrl = API_BASE_URL;
    }

    //getBaseUrl
    getBaseUrl() {
        return this.baseUrl;
    }

    // Helper method for making authenticated requests


    async fetchWithAuth(endpoint, options = {}) {
        const token = localStorage.getItem('token');
        const url = this.buildUrl(endpoint, options.params);

        try {
            const response = await fetch(url, {
                ...options,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token ? `Bearer ${token}` : '',
                    ...options.headers,
                },
            });

            if (response.status === 401) {
                localStorage.removeItem('token');
                window.location.reload();
                throw new Error('Session expired');
            }

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Request failed');
            }

            return data;
        } catch (error) {
            console.error(`API Error (${endpoint}):`, error);
            throw error;
        }
    }

    // Auth endpoints
    async login(username, password) {
        return this.fetchWithAuth('/api/login', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
        });
    }

    //Validate token
    async validateToken(token) {
        return this.fetchWithAuth('/api/validate-token', {
            method: 'POST',
            body: JSON.stringify({ token }),
        });
    }

    async logout() {
        return this.fetchWithAuth('/api/logout', {
            method: 'POST',
        });
    }

    // Conversations endpoints
    async getConversations() {
        return this.fetchWithAuth('/api/agent/conversations');
    }

    async getChatHistory(phone, page = 1, limit = 50) {
        return this.fetchWithAuth(
            `/api/agent/chat/${phone}?page=${page}&limit=${limit}`
        );
    }



    // Messages endpoints
    async sendMessage(recipientPhone, message, recipientName = '') {
        return this.fetchWithAuth('/api/agent/message', {
            method: 'POST',
            body: JSON.stringify({
                recipientPhone,
                recipientName,
                message,
            }),
        });
    }

    async sendMedia(file, recipientPhone, recipientName = '', caption = '') {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('recipientPhone', recipientPhone);
        formData.append('recipientName', recipientName);
        formData.append('caption', caption);
        formData.append('type', this.getFileType(file));

        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`${this.baseUrl}/api/agent/media`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    // Don't set Content-Type here, let browser set it with boundary
                },
                body: formData,
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message || 'Failed to upload media');
            }

            return response.json();
        } catch (error) {
            console.error('Media upload error:', error);
            throw error;
        }
    }

    async markMessagesAsRead(messages) {
        return this.fetchWithAuth('/api/agent/mark-read', {
            method: 'POST',
            body: JSON.stringify({ messages }),
        });
    }

    // Message status endpoints
    async getMessageStatus(messageId) {
        return this.fetchWithAuth(`/api/message-status/${messageId}`);
    }

    async getFailedMessages(timeRange = '24h') {
        return this.fetchWithAuth(`/api/failed-messages?timeRange=${timeRange}`);
    }

    async retryMessage(messageId) {
        return this.fetchWithAuth(`/api/retry-message/${messageId}`, {
            method: 'POST',
        });
    }

    // Helper methods
    getFileType(file) {
        if (file.type.startsWith('image/')) return 'image';
        if (file.type.startsWith('video/')) return 'video';
        if (file.type.startsWith('audio/')) return 'audio';
        return 'document';
    }




    // Dashboard API Methods
    async getReportsSummary(params) {
        return this.fetchWithAuth('/api/reports/summary', {
            method: 'GET',
            params
        });
    }

    async getAnalyticsSentiment(params) {
        return this.fetchWithAuth('/api/analytics/sentiment', {
            method: 'GET',
            params
        });
    }

    async getAnalyticsConversations(params) {
        return this.fetchWithAuth('/api/analytics/conversations', {
            method: 'GET',
            params
        });
    }





    // Metrics and Analytics endpoints
    async getEngagementMetrics(startDate, endDate) {
        return this.fetchWithAuth(
            `/api/analytics/engagement?startDate=${startDate}&endDate=${endDate}`
        );
    }

    async getMessageVolume(startDate, endDate) {
        return this.fetchWithAuth(
            `/api/analytics/messages/volume?startDate=${startDate}&endDate=${endDate}`
        );
    }

    async getUserRetention(startDate, endDate) {
        return this.fetchWithAuth(
            `/api/analytics/users/retention?startDate=${startDate}&endDate=${endDate}`
        );
    }

    async getResponseMetrics(startDate, endDate) {
        return this.fetchWithAuth(
            `/api/analytics/messages/response?startDate=${startDate}&endDate=${endDate}`
        );
    }

    async getActiveUsers(startDate, endDate) {
        return this.fetchWithAuth(
            `/api/analytics/users/active?startDate=${startDate}&endDate=${endDate}`
        );
    }

    async getDailyActivity(startDate, endDate) {
        return this.fetchWithAuth(
            `/api/analytics/activity/daily?startDate=${startDate}&endDate=${endDate}`
        );
    }


    // User Management endpoints
    async getUsers(params = {}) {
        const queryParams = new URLSearchParams();
        if (params.page) queryParams.append('page', params.page);
        if (params.limit) queryParams.append('limit', params.limit);
        if (params.search) queryParams.append('search', params.search);
        if (params.role) queryParams.append('role', params.role);
        //add a query parameter no cache
        queryParams.append('noCache', new Date().getTime());

        return this.fetchWithAuth(`/api/users?${queryParams.toString()}`);
    }


    async createUser(userData) {
        return this.fetchWithAuth('/api/register', {
            method: 'POST',
            body: JSON.stringify(userData)
        });
    }

    async updateUser(userId, userData) {
        return this.fetchWithAuth(`/api/users/${userId}`, {
            method: 'PUT',
            body: JSON.stringify(userData)
        });
    }

    async deleteUser(userId) {
        return this.fetchWithAuth(`/api/users/${userId}`, {
            method: 'DELETE'
        });
    }

    async changeUserPassword(userId, passwords) {
        return this.fetchWithAuth('/api/change-password', {
            method: 'POST',
            body: JSON.stringify(passwords)
        });
    }

    //change password for other users /api/users/:id/change-password newPassword
    async changeOtherUserPassword(userId, newPassword) {
        return this.fetchWithAuth(`/api/users/${userId}/change-password`, {
            method: 'POST',
            body: JSON.stringify(newPassword)
        });
    }




    // Template API Methods
    async getTemplates(filters = {}) {
        return this.fetchWithAuth('/api/templates', {
            method: 'GET',
            params: {
                category: filters.category || '',
                status: filters.status || '',
                language: filters.language || '',
                search: filters.search || '',
                limit: filters.limit || 10,
                offset: filters.offset || 0
            }
        });
    }

    async createTemplate(templateData) {
        return this.fetchWithAuth('/api/templates', {
            method: 'POST',
            body: JSON.stringify(templateData)
        });
    }

    async updateTemplate(templateId, data) {
        return this.fetchWithAuth(`/api/templates/${templateId}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        });
    }

    async deleteTemplate(templateId) {
        return this.fetchWithAuth(`/api/templates/${templateId}`, {
            method: 'DELETE'
        });
    }

    async getTemplateMetadata() {
        return this.fetchWithAuth('/api/templates/metadata/constants');
    }

    async getTemplateById(templateId) {
        return this.fetchWithAuth(`/api/templates/${templateId}`);
    }

    async searchTemplates(filters = {}) {
        return this.fetchWithAuth('/api/templates/search', {
            method: 'GET',
            params: {
                category: filters.category || '',
                name: filters.name || '',
                language: filters.language || '',
                status: filters.status || '',
                limit: filters.limit || 20,
                offset: filters.offset || 0
            }
        });
    }

    // Save Flow API Methods
    async saveFlow(reactFlowData) {
        try {
            // Format the flow data
            const flowData = {
                name: reactFlowData.name || 'New Flow', // Add a name field in your UI
                description: reactFlowData.description || '',
                nodes: reactFlowData.nodes.map(node => ({
                    id: node.id,
                    type: node.type,
                    position: {
                        x: node.position.x,
                        y: node.position.y
                    },
                    data: {
                        ...node.data,
                        // Clean up any UI-specific properties
                        selected: undefined,
                        dragging: undefined
                    },
                    width: node.width,
                    height: node.height,
                    deletable: node.deletable
                })),
                edges: reactFlowData.edges.map(edge => ({
                    id: edge.id,
                    source: edge.source,
                    sourceHandle: edge.sourceHandle,
                    target: edge.target,
                    targetHandle: edge.targetHandle,
                    type: edge.type || 'smoothstep',
                    animated: edge.animated || true,
                    style: edge.style || { stroke: '#888' },
                    label: edge.label || ''
                })),
                // Add any additional metadata
                metadata: {
                    lastModified: new Date().toISOString(),
                    version: reactFlowData.version || 1,
                    status: 'draft'
                }
            };

            return await this.fetchWithAuth(`/api/flows`, {
                method: 'POST',
                body: JSON.stringify(flowData)
            });
        } catch (error) {
            console.error('Error saving flow:', error);
            throw error;
        }
    }


    async updateFlow(flowId, reactFlowData) {
        try {
            const flowData = {
                ...reactFlowData,
                metadata: {
                    lastModified: new Date().toISOString(),
                    version: (reactFlowData.version || 1) + 1
                }
            };

            return await this.fetchWithAuth(`/api/flows/${flowId}`, {
                method: 'PUT',
                body: JSON.stringify(flowData)
            });
        } catch (error) {
            console.error('Error updating flow:', error);
            throw error;
        }
    }

    async getFlow(flowId) {
        try {
            return await this.fetchWithAuth(`/api/flows/${flowId}`);
        } catch (error) {
            console.error('Error getting flow:', error);
            throw error;
        }
    }

    async validateFlow(flowData) {
        // Basic flow validation
        if (!flowData.nodes || !flowData.edges) {
            throw new Error('Invalid flow data: missing nodes or edges');
        }

        // Validate start node exists
        const startNode = flowData.nodes.find(node => node.type === 'start');
        if (!startNode) {
            throw new Error('Flow must have a start node');
        }

        // Validate node connections
        const nodeIds = new Set(flowData.nodes.map(node => node.id));
        for (const edge of flowData.edges) {
            if (!nodeIds.has(edge.source)) {
                throw new Error(`Invalid edge: source node ${edge.source} not found`);
            }
            if (!nodeIds.has(edge.target)) {
                throw new Error(`Invalid edge: target node ${edge.target} not found`);
            }
        }

        // Validate node data
        for (const node of flowData.nodes) {
            switch (node.type) {
                case 'message':
                    if (!node.data?.message) {
                        throw new Error(`Message node ${node.id} must have a message`);
                    }
                    break;
                case 'condition':
                    if (!node.data?.variable || !node.data?.operator) {
                        throw new Error(`Condition node ${node.id} must have a variable and operator`);
                    }
                    break;
                case 'quickReply':
                    if (!node.data?.question || !node.data?.options?.length) {
                        throw new Error(`Quick reply node ${node.id} must have a question and options`);
                    }
                    break;
                case 'action':
                    if (!node.data?.actionType) {
                        throw new Error(`Action node ${node.id} must have an action type`);
                    }
                    break;
                default:
                    throw new Error(`Unknown node type: ${node.type}`);


            }
        }

        return true;
    }
    // In api.js, add these methods:

    async getFlows(filters = {}) {
        const queryParams = new URLSearchParams();

        // Add pagination parameters
        queryParams.append('page', filters.page || 1);
        queryParams.append('limit', filters.limit || 10);

        // Add search if provided
        if (filters.search) {
            queryParams.append('search', filters.search);
        }

        // Add status filter if provided
        if (filters.status === 'active') {
            queryParams.append('isActive', 'true');
        } else if (filters.status === 'inactive') {
            queryParams.append('isActive', 'false');
        }

        // Add no-cache parameter to prevent caching
        queryParams.append('noCache', new Date().getTime());

        return this.fetchWithAuth(`/api/listflows?${queryParams.toString()}`);
    }

    async deleteFlow(flowId) {
        return this.fetchWithAuth(`/api/flows/${flowId}`, {
            method: 'DELETE'
        });
    }

    // start flow 
    async startFlow({ flowId, userId, metadata = {} }) {
        return this.fetchWithAuth('/api/sessions/start', {
            method: 'POST',
            body: JSON.stringify({
                flowId,
                userId,
                metadata
            })
        });
    }

    async continueFlow(sessionId, { input }) {
        return this.fetchWithAuth(`/api/sessions/${sessionId}/continue`, {
            method: 'POST',
            body: JSON.stringify({ input })
        });
    }

    async endSession(sessionId, { reason }) {
        return this.fetchWithAuth(`/api/sessions/${sessionId}/end`, {
            method: 'POST',
            body: JSON.stringify({ reason })
        });
    }

    async getSessionState(sessionId) {
        return this.fetchWithAuth(`/api/sessions/${sessionId}`);
    }


    ///api/logout-all - POST - Logout all Sessions for a user

    // Helper method to handle URL parameters
    buildUrl(endpoint, params) {
        const url = new URL(`${this.baseUrl}${endpoint}`);
        if (params) {
            Object.keys(params).forEach(key =>
                url.searchParams.append(key, params[key])
            );
        }
        return url.toString();
    }





}

export const apiService = new ApiService();