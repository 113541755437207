import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { apiService } from '../../services/api';
import DateRangePicker from './DateRangePicker';
import {
    RefreshCcw,
    Users,
    UserPlus,
    Filter,
    Search,
    Edit2,
    Trash2,
    Lock
} from 'lucide-react';
import { format, subDays } from 'date-fns';
import UserModal from './modals/UserModal';
import PasswordModal from './modals/PasswordModal';

const UserManagement = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userData, setUserData] = useState({
        users: [],
        pagination: {
            currentPage: 1,
            totalPages: 1,
            totalUsers: 0,
            limit: 10,
            hasNextPage: false,
            hasPrevPage: false
        }
    });
    const [selectedUser, setSelectedUser] = useState(null);
    const [showUserModal, setShowUserModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [roleFilter, setRoleFilter] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (!user?.role === 'admin') {
            navigate('/');
            return;
        }
        loadUsers();
    }, [user, navigate, currentPage]);

    const loadUsers = async () => {
        try {
            setLoading(true);
            setError(null);

            const { success, data } = await apiService.getUsers({
                page: currentPage,
                limit: 10,
                search: searchTerm || undefined,
                role: roleFilter !== 'all' ? roleFilter : undefined,
            });

            if (success && data?.users) {
                setUserData({
                    users: data.users,
                    pagination: data.pagination || {
                        currentPage: 1,
                        totalPages: 1,
                        totalUsers: 0,
                        hasNextPage: false,
                        hasPrevPage: false,
                    },
                });
            } else {
                throw new Error('Invalid response format');
            }
        } catch (err) {
            setError(`Failed to load users: ${err.message}`);
            console.error('User management error:', err);
        } finally {
            setLoading(false);
        }
    };

    // Refresh users when search or filter changes
    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            setCurrentPage(1); // Reset to first page when filters change
            loadUsers();
        }, 300);

        return () => clearTimeout(debounceTimer);
    }, [searchTerm, roleFilter]);

    const handleCreateUser = async (userData) => {
        try {
            setError(null);
            await apiService.createUser(userData);
            loadUsers();
            setShowUserModal(false);
        } catch (err) {
            setError(err.message || 'Failed to create user');
        }
    };

    const handleUpdateUser = async (userId, userData) => {
        try {
            setError(null);
            await apiService.updateUser(userId, userData);
            loadUsers();
            setShowUserModal(false);
        } catch (err) {
            setError(err.message || 'Failed to update user');
        }
    };

    const handleDeleteUser = async (userId) => {
        if (!window.confirm('Are you sure you want to delete this user?')) return;

        try {
            setError(null);
            await apiService.deleteUser(userId);
            loadUsers();
        } catch (err) {
            setError(err.message || 'Failed to delete user');
        }
    };

    const handleChangePassword = async (userId, passwords) => {
        try {
            setError(null);
            await apiService.changeOtherUserPassword(userId, passwords);
            setShowPasswordModal(false);
        } catch (err) {
            setError(err.message || 'Failed to change password');
        }
    };

    // Filter users client-side (if needed)
    const filteredUsers = userData.users.filter(user => {
        const matchesSearch = !searchTerm ||
            user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesRole = roleFilter === 'all' || user.role === roleFilter;
        return matchesSearch && matchesRole;
    });

    const renderPagination = () => {
        const { currentPage, totalPages } = userData.pagination;

        return (
            <div className="flex justify-center space-x-2 mt-4">
                <button
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    disabled={!userData.pagination.hasPrevPage}
                    className="px-3 py-1 rounded border disabled:opacity-50"
                >
                    Previous
                </button>
                <span className="px-3 py-1">
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    onClick={() => setCurrentPage(prev => prev + 1)}
                    disabled={!userData.pagination.hasNextPage}
                    className="px-3 py-1 rounded border disabled:opacity-50"
                >
                    Next
                </button>
            </div>
        );
    };



    return (
        <div className="h-screen flex flex-col bg-gray-50">
            {/* Header */}
            <header className="bg-white shadow sticky top-0 z-10">
                <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col space-y-4 sm:flex-row sm:justify-between sm:items-center sm:space-y-0">
                        <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">
                            User Management
                        </h1>

                        <div className="flex flex-col space-y-3 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-4">
                            <button
                                onClick={() => {
                                    setSelectedUser(null);
                                    setShowUserModal(true);
                                }}
                                className="flex items-center justify-center gap-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                            >
                                <UserPlus size={16} />
                                Add User
                            </button>
                        </div>
                    </div>
                </div>
            </header>

            {/* Error Display */}
            {error && (
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                        {error}
                        <button
                            onClick={() => setError(null)}
                            className="absolute right-0 top-0 p-4"
                        >
                            ×
                        </button>
                    </div>
                </div>
            )}

            {/* Main Content */}
            <main className="flex-1 py-6 px-4 sm:px-6 overflow-auto">
                <div className="max-w-7xl mx-auto">
                    {/* Filters */}
                    <div className="bg-white rounded-lg shadow p-4 mb-6">
                        <div className="flex flex-col sm:flex-row gap-4">
                            <div className="flex-1">
                                <div className="relative">
                                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                                    <input
                                        type="text"
                                        placeholder="Search users..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                    />
                                </div>
                            </div>
                            <div className="sm:w-48">
                                <select
                                    value={roleFilter}
                                    onChange={(e) => setRoleFilter(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                >
                                    <option value="all">All Roles</option>
                                    <option value="admin">Admin</option>
                                    <option value="user">User</option>
                                </select>
                            </div>
                            <button
                                onClick={loadUsers}
                                disabled={loading}
                                className="flex items-center justify-center gap-2 bg-gray-100 text-gray-700 px-4 py-2 rounded hover:bg-gray-200"
                            >
                                <RefreshCcw size={16} className={loading ? 'animate-spin' : ''} />
                                Refresh
                            </button>
                        </div>
                    </div>

                    {/* Users Table */}
                    <div className="bg-white rounded-lg shadow overflow-hidden">
                        <div className="min-w-full divide-y divide-gray-200">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            User
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Role
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Status
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Last Login
                                        </th>
                                        <th scope="col" className="relative px-6 py-3">
                                            <span className="sr-only">Actions</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {filteredUsers.map((user) => (
                                        <tr key={user._id}>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0 h-10 w-10">
                                                        <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center">
                                                            <span className="text-blue-600 font-medium text-lg">
                                                                {user.username[0].toUpperCase()}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="ml-4">
                                                        <div className="text-sm font-medium text-gray-900">
                                                            {user.username}
                                                        </div>
                                                        <div className="text-sm text-gray-500">
                                                            {user.email}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.role === 'admin'
                                                    ? 'bg-purple-100 text-purple-800'
                                                    : 'bg-green-100 text-green-800'
                                                    }`}>
                                                    {user.role}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.isActive
                                                    ? 'bg-green-100 text-green-800'
                                                    : 'bg-red-100 text-red-800'
                                                    }`}>
                                                    {user.isActive ? 'Active' : 'Inactive'}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {user.lastLogin ? format(new Date(user.lastLogin), 'PPp') : 'Never'}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                <div className="flex justify-end space-x-2">
                                                    <button
                                                        onClick={() => {
                                                            setSelectedUser(user);
                                                            setShowUserModal(true);
                                                        }}
                                                        className="text-blue-600 hover:text-blue-900"
                                                    >
                                                        <Edit2 size={16} />
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            setSelectedUser(user);
                                                            setShowPasswordModal(true);
                                                        }}
                                                        className="text-yellow-600 hover:text-yellow-900"
                                                    >
                                                        <Lock size={16} />
                                                    </button>
                                                    <button
                                                        onClick={() => handleDeleteUser(user._id)}
                                                        className="text-red-600 hover:text-red-900"
                                                    >
                                                        <Trash2 size={16} />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* Pagination */}
                    {renderPagination()}
                </div>
            </main>

            {/* Modals */}
            {showUserModal && (
                <UserModal
                    user={selectedUser}
                    onClose={() => setShowUserModal(false)}
                    onSubmit={selectedUser ? handleUpdateUser : handleCreateUser}
                />
            )}

            {showPasswordModal && (
                <PasswordModal
                    userId={selectedUser?._id}
                    onClose={() => setShowPasswordModal(false)}
                    onSubmit={handleChangePassword}
                />
            )}
        </div>
    );
};

export default UserManagement;