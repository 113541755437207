//src/components/flows/ChatWidget.js
import React, { useState, useEffect, useRef } from 'react';
import { MessageSquare, RefreshCw, Send, Image, FileText, Video, Mic } from 'lucide-react';
import { apiService } from '../../services/api';

const TYPING_SPEED = 25; // ms per character
const MIN_MESSAGE_DELAY = 500; // minimum delay between messages

const MediaMessage = ({ type, url, caption }) => {
    switch (type) {
        case 'image':
            return (
                <div className="max-w-sm">
                    <img
                        src={url}
                        alt={caption || 'Image'}
                        className="rounded-lg w-full h-auto object-cover"
                    />
                    {caption && (
                        <p className="mt-1 text-sm text-gray-600 whitespace-pre-line">{caption}</p>
                    )}
                </div>
            );

        case 'video':
            return (
                <div className="max-w-sm">
                    <video
                        controls
                        className="rounded-lg w-full"
                    >
                        <source src={url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    {caption && (
                        <p className="mt-1 text-sm text-gray-600 whitespace-pre-line">{caption}</p>
                    )}
                </div>
            );

        case 'audio':
            return (
                <div className="max-w-sm">
                    <audio
                        controls
                        className="w-full"
                    >
                        <source src={url} type="audio/mpeg" />
                        Your browser does not support the audio tag.
                    </audio>
                    {caption && (
                        <p className="mt-1 text-sm text-gray-600 whitespace-pre-line">{caption}</p>
                    )}
                </div>
            );

        case 'document':
            return (
                <div className="max-w-sm p-4 bg-gray-50 rounded-lg border border-gray-200">
                    <div className="flex items-center gap-2 text-gray-700">
                        <FileText size={20} />
                        <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:underline"
                        >
                            {caption || 'Download Document'}
                        </a>
                    </div>
                </div>
            );

        default:
            return null;
    }
};

const ChatWidget = ({ flowId }) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [sessionId, setSessionId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [messageQueue, setMessageQueue] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showTypingIndicator, setShowTypingIndicator] = useState(false);
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages, showTypingIndicator]);

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const processMessageWithTypingEffect = async (message) => {
        if (message.type === 'media') {
            // Don't show typing indicator for media messages
            setMessages(prev => [...prev, {
                type: 'bot',
                contentType: 'media',
                ...message
            }]);
            await delay(MIN_MESSAGE_DELAY);
        } else {
            setShowTypingIndicator(true);
            await delay(Math.min(message.message?.length * TYPING_SPEED || MIN_MESSAGE_DELAY, 2000));
            setShowTypingIndicator(false);

            setMessages(prev => [...prev, {
                type: 'bot',
                contentType: message.type,
                ...message
            }]);

            await delay(MIN_MESSAGE_DELAY);
        }
    };

    // Process message queue
    useEffect(() => {
        const processQueue = async () => {
            if (messageQueue.length > 0 && !isProcessing) {
                setIsProcessing(true);
                const currentMessage = messageQueue[0];

                if (currentMessage.waitForInput) {
                    // For messages requiring input, just display them and wait
                    setMessages(prev => [...prev, {
                        type: 'bot',
                        contentType: currentMessage.type,
                        ...currentMessage
                    }]);
                    setMessageQueue(prev => prev.slice(1));
                    setIsProcessing(false);
                } else {
                    // For non-input messages, add typing effect
                    await processMessageWithTypingEffect(currentMessage);
                    setMessageQueue(prev => prev.slice(1));
                    setIsProcessing(false);
                }
            }
        };

        processQueue();
    }, [messageQueue, isProcessing]);

    const startNewSession = async () => {
        try {
            setIsLoading(true);
            setMessages([]);
            setMessageQueue([]);

            if (sessionId) {
                await apiService.endSession(sessionId, { reason: 'user_refresh' });
            }

            const result = await apiService.startFlow({
                flowId,
                userId: 'test-user-' + Date.now(),
                metadata: { source: 'test-widget' }
            });

            setSessionId(result.sessionId);

            if (Array.isArray(result.result)) {
                setMessageQueue(result.result);
            } else if (result.result) {
                setMessageQueue([result.result]);
            }
        } catch (error) {
            console.error('Error starting session:', error);
            setMessages([{
                type: 'error',
                content: 'Failed to start session: ' + error.message
            }]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        startNewSession();
    }, [flowId]);
    /*
    const handleSend = async () => {
        if (!input.trim() || !sessionId) return;

        try {
            setIsLoading(true);
            const userMessage = input;
            setInput('');

            setMessages(prev => [...prev, {
                type: 'user',
                content: userMessage
            }]);

            const result = await apiService.continueFlow(sessionId, {
                input: userMessage
            });

            if (Array.isArray(result.result)) {
                setMessageQueue(result.result);
            } else if (result.result) {
                setMessageQueue([result.result]);
            }

            if (result.status === 'completed') {
                await delay(MIN_MESSAGE_DELAY);
                setMessages(prev => [...prev, {
                    type: 'system',
                    content: 'Flow completed'
                }]);
                setSessionId(null);
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setMessages(prev => [...prev, {
                type: 'error',
                content: 'Error: ' + error.message
            }]);
        } finally {
            setIsLoading(false);
        }
    };
    */

    const handleSend = async () => {
        if (!input.trim() || !sessionId) return;

        try {
            setIsLoading(true);
            const userMessage = input;
            setInput('');

            // Add user message immediately
            setMessages(prev => [...prev, {
                type: 'user',
                content: userMessage
            }]);

            const result = await apiService.continueFlow(sessionId, {
                input: userMessage
            });

            // Add messages to queue
            if (Array.isArray(result.result)) {
                setMessageQueue(prev => [...prev, ...result.result]);
            } else if (result.result) {
                setMessageQueue(prev => [...prev, result.result]);
            }

            // If flow is completed, add completion message to queue instead of showing immediately
            if (result.status === 'completed') {
                setMessageQueue(prev => [...prev, {
                    type: 'system',
                    content: 'Flow completed'
                }]);
                // Only set sessionId to null after queue is processed
                setTimeout(() => {
                    setSessionId(null);
                }, messageQueue.length * MIN_MESSAGE_DELAY + 1000); // Add buffer time
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setMessages(prev => [...prev, {
                type: 'error',
                content: 'Error: ' + error.message
            }]);
        } finally {
            setIsLoading(false);
        }
    };

    const renderMessageContent = (message) => {
        if (message.type === 'error' || message.type === 'system') {
            return <p>{message.content}</p>;
        }

        switch (message.contentType) {
            case 'media':
                return (
                    <MediaMessage
                        type={message.mediaType}
                        url={message.mediaUrl}
                        caption={message.caption}
                    />
                );

            case 'quickReply':
                return (
                    <div className="space-y-2">
                        <p className="whitespace-pre-line">{message.question}</p>
                        <div className="mt-2 space-y-2">
                            {message.options?.map((option, i) => (
                                <button
                                    key={i}
                                    onClick={() => {
                                        setInput(option);
                                        handleSend();
                                    }}
                                    className="block w-full text-left px-3 py-2 rounded bg-gray-100 hover:bg-gray-200 transition-colors text-sm"
                                >
                                    {option}
                                </button>
                            ))}
                        </div>
                    </div>
                );

            case 'inputCollection':
                return (
                    <div className="space-y-2">
                        <p className="whitespace-pre-line">{message.question}</p>
                        {message.inputType && (
                            <input
                                type={message.inputType}
                                className="w-full mt-2 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                                placeholder={`Enter ${message.inputType}...`}
                                onChange={(e) => setInput(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                            />
                        )}
                    </div>
                );

            default:
                return <p className="whitespace-pre-line">{message.message || message.content}</p>;
        }
    };

    return (
        <div className="w-full max-w-md mx-auto bg-white rounded-lg shadow-lg overflow-hidden border">
            <div className="bg-blue-600 text-white p-4 flex justify-between items-center">
                <div className="flex items-center gap-2">
                    <MessageSquare size={20} />
                    <span className="font-medium">Chat Flow Test</span>
                </div>
                <button
                    onClick={startNewSession}
                    disabled={isLoading}
                    className="p-2 hover:bg-blue-700 rounded-full transition-colors disabled:opacity-50"
                    title="Start new session"
                >
                    <RefreshCw size={20} className={isLoading ? 'animate-spin' : ''} />
                </button>
            </div>

            <div className="h-96 overflow-y-auto p-4 space-y-4 bg-gray-50">
                {messages.map((message, index) => (
                    <div key={index} className={`flex ${message.type === 'user' ? 'justify-end' : 'justify-start'}`}>
                        <div
                            className={`max-w-[80%] rounded-lg p-3 ${message.type === 'user'
                                ? 'bg-blue-600 text-white'
                                : message.type === 'error'
                                    ? 'bg-red-100 text-red-800'
                                    : message.type === 'system'
                                        ? 'bg-gray-200 text-gray-800 text-center w-full'
                                        : 'bg-white text-gray-800 shadow'
                                }`}
                        >
                            {renderMessageContent(message)}
                        </div>
                    </div>
                ))}
                {showTypingIndicator && (
                    <div className="flex justify-start">
                        <div className="bg-gray-100 rounded-lg p-3 space-x-1 flex items-center">
                            <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0ms' }} />
                            <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '150ms' }} />
                            <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '300ms' }} />
                        </div>
                    </div>
                )}
                <div ref={messagesEndRef} />
            </div>

            <div className="p-4 border-t">
                <div className="flex gap-2">
                    <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                        placeholder="Type your message..."
                        className="flex-1 rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:border-blue-500"
                        disabled={!sessionId || isLoading}
                    />
                    <button
                        onClick={handleSend}
                        disabled={!sessionId || isLoading || !input.trim()}
                        className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        <Send size={20} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChatWidget;