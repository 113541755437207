import React, { useState, useEffect } from 'react';
import { Search, X, Info } from 'lucide-react';
import { apiService } from '../../services/api';

const FlowSelectionProperties = ({ data, onChange }) => {
    const [availableFlows, setAvailableFlows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        loadFlows();
    }, []);

    const loadFlows = async () => {
        try {
            setIsLoading(true);
            setError(null);
            const response = await apiService.getFlows({
                status: 'active',
                limit: 100, // Increased limit
                search: searchTerm
            });
            setAvailableFlows(response.data.data || []);
        } catch (err) {
            console.error('Error loading flows:', err);
            setError('Failed to load available flows');
            setAvailableFlows([]);
        } finally {
            setIsLoading(false);
        }
    };

    // Debounce search
    useEffect(() => {
        const timer = setTimeout(() => {
            if (searchTerm) {
                loadFlows();
            }
        }, 300);

        return () => clearTimeout(timer);
    }, [searchTerm]);

    const updateData = (updates) => {
        onChange({
            ...data,
            ...updates
        });
    };

    const selectFlow = (flow) => {
        updateData({
            targetFlow: flow._id,
            targetFlowName: flow.name,
            targetFlowVersion: flow.version
        });
        setShowDropdown(false);
    };

    const clearSelection = () => {
        updateData({
            targetFlow: null,
            targetFlowName: null,
            targetFlowVersion: null
        });
    };

    return (
        <div className="space-y-6">
            {/* Target Flow Selection */}
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    Target Flow
                </label>
                <div className="relative">
                    {/* Search Input */}
                    <div className="relative">
                        <input
                            type="text"
                            className="w-full rounded-md border-gray-300 pl-10 pr-4 py-2 focus:border-amber-500 focus:ring-amber-500"
                            placeholder="Search flows..."
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                                setShowDropdown(true);
                            }}
                            onFocus={() => setShowDropdown(true)}
                        />
                        <Search
                            className="absolute left-3 top-2.5 text-gray-400"
                            size={18}
                        />
                        {data.targetFlow && (
                            <button
                                onClick={clearSelection}
                                className="absolute right-3 top-2.5 text-gray-400 hover:text-gray-600"
                            >
                                <X size={18} />
                            </button>
                        )}
                    </div>

                    {/* Selected Flow Display */}
                    {data.targetFlow && (
                        <div className="mt-2 p-2 bg-green-50 rounded-md border border-green-200">
                            <p className="text-sm text-green-700 font-medium">
                                Selected: {data.targetFlowName} (v{data.targetFlowVersion})
                            </p>
                        </div>
                    )}

                    {/* Dropdown */}
                    {showDropdown && (
                        <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg border border-gray-200 max-h-60 overflow-auto">
                            {isLoading ? (
                                <div className="p-2 text-sm text-gray-500">Loading...</div>
                            ) : error ? (
                                <div className="p-2 text-sm text-red-500">{error}</div>
                            ) : availableFlows.length === 0 ? (
                                <div className="p-2 text-sm text-gray-500">No flows found</div>
                            ) : (
                                <div className="py-1">
                                    {availableFlows.map((flow) => (
                                        <button
                                            key={flow._id}
                                            className={`w-full px-4 py-2 text-left text-sm hover:bg-gray-100 focus:bg-gray-100 
                        ${flow._id === data.targetFlow ? 'bg-amber-50' : ''}`}
                                            onClick={() => selectFlow(flow)}
                                        >
                                            <div className="font-medium">{flow.name}</div>
                                            <div className="text-xs text-gray-500">
                                                Version {flow.version} • {flow.description || 'No description'}
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {/* Return to Source Option */}
            {data.targetFlow && (
                <div>
                    <div className="mt-2 p-4 bg-amber-50 rounded-lg border border-amber-200 flex items-start gap-3">
                        <Info className="h-5 w-5 text-amber-600 flex-shrink-0 mt-0.5" />
                        <div className="flex-1">
                            <p className="text-sm text-amber-700 font-medium">
                                Please make sure the version of the selected flow matches the version of the session flow.
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FlowSelectionProperties;