import React from 'react';
import { useSearchParams } from 'react-router-dom';
import ChatWidget from './ChatWidget';

const EmbeddedChatPage = () => {
    const [searchParams] = useSearchParams();
    const flowId = searchParams.get('flowId');

    // Add some basic styles for full-page embed
    const containerStyle = {
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#f3f4f6'
    };

    const widgetStyle = {
        width: '100%',
        maxWidth: '400px',
        height: '600px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '12px',
        overflow: 'hidden'
    };

    if (!flowId) {
        return (
            <div style={containerStyle}>
                <div style={{ ...widgetStyle, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="text-red-600 p-4 text-center">
                        <h2 className="text-lg font-medium">Configuration Error</h2>
                        <p>Flow ID is required. Add it to the URL like:</p>
                        <code className="block mt-2 p-2 bg-gray-100 rounded">
                            /embedded-chat?flowId=your-flow-id
                        </code>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div style={containerStyle}>
            <div style={widgetStyle}>
                <ChatWidget flowId={flowId} />
            </div>
        </div>
    );
};

export default EmbeddedChatPage;