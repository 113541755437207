// src/components/layouts/AdminLayout.js
import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import {
    BarChart2,
    MessageSquare,
    Users,
    Settings,
    ChevronLeft,
    ChevronRight,
    LogOut,
    Menu,
    CircleGauge,
    Laugh,
    FileText,
    Share2
} from 'lucide-react';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '../ui/tooltip';

const AdminLayout = ({ children }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const { user, logout } = useAuth();
    const location = useLocation();

    useEffect(() => {
        // Get saved state from localStorage or default to true (collapsed)
        const savedState = localStorage.getItem('sidebarCollapsed');
        setIsCollapsed(savedState === null ? true : JSON.parse(savedState));
    }, []);

    // Update localStorage when state changes (optional)
    const toggleSidebar = () => {
        const newState = !isCollapsed;
        setIsCollapsed(newState);
        localStorage.setItem('sidebarCollapsed', JSON.stringify(newState));
    };

    const navigation = [
        {
            name: 'Dashboard',
            icon: BarChart2,
            href: '/admin/dashboard/overview',
            matchPaths: ['/admin/dashboard/overview'],
            description: 'View analytics and reports',
            requiredRole: 'admin'
        },
        //Engagement Dashboard
        {
            name: 'Engagement',
            icon: CircleGauge,
            href: '/admin/dashboard/metrics',
            matchPaths: ['/admin/dashboard/metrics'],
            description: 'View engagement metrics',
            requiredRole: 'admin'
        },
        //Sentiment Dashboard
        {
            name: 'Sentiment',
            icon: Laugh,
            href: '/admin/dashboard/sentiment',
            matchPaths: ['/admin/dashboard/sentiment'],
            description: 'View sentiment analysis',
            requiredRole: 'admin'
        },
        {
            name: 'Chat',
            icon: MessageSquare,
            href: '/chat',
            matchPaths: ['/chat'],
            description: 'Manage conversations'
        },
        {
            name: 'Templates',
            icon: FileText,
            href: '/templates',
            matchPaths: ['/templates', '/templates/create', '/templates/edit'],
            description: 'Manage message templates',
            requiredRole: 'admin'
        },

        {
            name: 'Flow Builder',
            icon: Share2,
            href: '/flows/list',
            matchPaths: ['/flows/list', '/flows/create', '/flows/edit', '/flows'],
            description: 'Flow Builder'
        },

        /* {
             name: 'Flow Builder',
             icon: Share2,
             href: '/flows',
             matchPaths: ['/flows'],
             description: 'Design conversation flows'
         },*/
        {
            name: 'Users',
            icon: Users,
            href: '/admin/users',
            matchPaths: ['/admin/users'],
            description: 'Manage user accounts',
            requiredRole: 'admin'
        },
        {
            name: 'Settings',
            icon: Settings,
            href: '/admin/settings',
            matchPaths: ['/admin/settings'],
            description: 'Configure system settings',
            requiredRole: 'admin'

        },
    ];

    const isActivePath = (matchPaths) => {
        return matchPaths.some(path => location.pathname.startsWith(path));
    };

    const NavItem = ({ item }) => {
        const Icon = item.icon;
        const isActive = isActivePath(item.matchPaths);

        return (
            <TooltipProvider delayDuration={300}>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <NavLink
                            to={item.href}
                            className={`
                                flex items-center px-2 py-2 rounded-lg
                                ${isActive
                                    ? 'bg-blue-100 text-blue-600'
                                    : 'text-gray-600 hover:bg-gray-100'
                                }
                                ${isCollapsed ? 'justify-center' : ''}
                                transition-colors duration-200
                            `}
                        >
                            <Icon size={20} />
                            {!isCollapsed && (
                                <span className="ml-3">{item.name}</span>
                            )}
                        </NavLink>
                    </TooltipTrigger>
                    {isCollapsed && (
                        <TooltipContent side="right" className="flex flex-col">
                            <p className="font-semibold">{item.name}</p>
                            <p className="text-xs text-gray-500">{item.description}</p>
                        </TooltipContent>
                    )}
                </Tooltip>
            </TooltipProvider>
        );
    };

    return (
        <div className="flex h-screen bg-gray-100">
            {/* Sidebar */}
            <div
                className={`${isCollapsed ? 'w-16' : 'w-64'
                    } bg-white shadow-lg transition-all duration-300 ease-in-out relative`}
            >
                {/* Toggle Button */}
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <button
                                onClick={toggleSidebar}
                                className="absolute -right-3 top-8 bg-white rounded-full p-1 shadow-md hover:bg-gray-100"
                            >
                                {isCollapsed ? (
                                    <ChevronRight size={20} />
                                ) : (
                                    <ChevronLeft size={20} />
                                )}
                            </button>
                        </TooltipTrigger>
                        <TooltipContent side="right">
                            {isCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>

                {/* Sidebar Content */}
                <div className="flex flex-col h-full">
                    {/* Logo */}
                    <div className={`
                        flex items-center  h-16 
                        ${isCollapsed ? 'px-2 justify-center' : 'px-4'}
                    `}>
                        <Menu className="text-blue-600" size={24} />
                        {!isCollapsed && (
                            <span className="ml-2 font-semibold text-xl text-gray-800">
                                Admin Panel
                            </span>
                        )}
                    </div>

                    {/* Navigation */}
                    <nav className="flex-1 px-2 py-4 space-y-2">
                        {navigation.map((item) => {
                            if (item.requiredRole && item.requiredRole !== user.role) {
                                return null;
                            }
                            return <NavItem key={item.name} item={item} />;
                        })}
                    </nav>

                    {/* User Section */}
                    <div className={`
                        p-4 border-t 
                        ${isCollapsed ? 'text-center' : ''}
                    `}>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <div className="flex items-center">
                                        <div className={`
                                            flex-shrink-0 w-8 h-8 bg-blue-500 rounded-full 
                                            flex items-center justify-center text-white font-semibold
                                            cursor-pointer
                                        `}>
                                            {user?.username?.[0]?.toUpperCase() || 'U'}
                                        </div>
                                        {!isCollapsed && (
                                            <div className="ml-3">
                                                <p className="text-sm font-medium text-gray-700">
                                                    {user?.username || 'User'}
                                                    <small className="block text-xs text-gray-500">{user?.role || 'Role'}</small>
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </TooltipTrigger>
                                {isCollapsed && (
                                    <TooltipContent side="right">
                                        <p className="font-semibold">{user?.username || 'User'}</p>
                                        <p className="text-xs text-gray-500">Logged in user</p>
                                    </TooltipContent>
                                )}
                            </Tooltip>
                        </TooltipProvider>

                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <button
                                        onClick={logout}
                                        className={`
                                            mt-2 flex items-center text-sm text-red-600 hover:text-red-800
                                            ${isCollapsed ? 'justify-center w-full' : ''}
                                        `}
                                    >
                                        <LogOut size={16} />
                                        {!isCollapsed && <span className="ml-2">Logout</span>}
                                    </button>
                                </TooltipTrigger>
                                {isCollapsed && (
                                    <TooltipContent side="right">
                                        <p>Logout</p>
                                    </TooltipContent>
                                )}
                            </Tooltip>
                        </TooltipProvider>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="flex-1 overflow-auto">
                {children}
            </div>
        </div>
    );
};

export default AdminLayout;