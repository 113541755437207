//src/components/flows/FloatingChatWindow.js
import React, { useState } from 'react';
import { MessageSquare, X, Save } from 'lucide-react';
import ChatWidget from './ChatWidget';

const FloatingChatWindow = ({ flowId, isOpen, onClose, onSave }) => {
    const [position, setPosition] = useState({ x: window.innerWidth - 450, y: 100 });

    if (!isOpen) return null;

    return (
        <div
            className="fixed bg-white rounded-lg shadow-2xl"
            style={{
                left: position.x,
                top: position.y,
                width: 400,
                zIndex: 9999
            }}
        >
            {/* Draggable Header */}
            <div
                className="bg-gray-100 p-2 rounded-t-lg cursor-move flex justify-between items-center"
                onMouseDown={(e) => {
                    const startX = e.pageX - position.x;
                    const startY = e.pageY - position.y;

                    const handleMouseMove = (e) => {
                        setPosition({
                            x: e.pageX - startX,
                            y: e.pageY - startY
                        });
                    };

                    const handleMouseUp = () => {
                        document.removeEventListener('mousemove', handleMouseMove);
                        document.removeEventListener('mouseup', handleMouseUp);
                    };

                    document.addEventListener('mousemove', handleMouseMove);
                    document.addEventListener('mouseup', handleMouseUp);
                }}
            >
                <div className="flex items-center gap-2">
                    <MessageSquare size={16} />
                    <span className="font-medium">Test Flow</span>
                </div>
                <button
                    onClick={onClose}
                    className="p-1 hover:bg-gray-200 rounded-full"
                >
                    <X size={16} />
                </button>
            </div>

            {/* Content */}
            <div className="p-4">
                {flowId ? (
                    <ChatWidget flowId={flowId} />
                ) : (
                    <div className="text-center py-8 px-4">
                        <div className="mb-4 text-gray-600">
                            Please save your flow before testing
                        </div>
                        <button
                            onClick={onSave}
                            className="inline-flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                        >
                            <Save size={16} />
                            Save Flow
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FloatingChatWindow;